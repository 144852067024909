/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import UmbilicalGantryXAxisSlide_Model from './UmbilicalGantryXAxisSlide.glb';

export default function UmbilicalGantryXAxisSlide(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(UmbilicalGantryXAxisSlide_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        position={[4.39, -3, 73.39]}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.NutConnector.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? materials['Plastic - Matte (Blue)']
              : material
          }
        />
      </group>
      <group position={[0.39, -1, 44.62]} rotation={[0, 0, -Math.PI / 2]}>
        <mesh
          geometry={nodes.Body1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1.material
              : material
          }
        />
      </group>
      <group position={[-0.43, 4, 44.3]} rotation={[0, 0, -Math.PI / 2]}>
        <group position={[0, 0, 3.94]}>
          <mesh
            geometry={nodes.Body1_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_1.material
                : material
            }
          />
        </group>
        <group position={[0, 0, -0.8]} rotation={[Math.PI, 0, Math.PI]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_2.material
                : material
            }
          />
        </group>
        <group rotation={[-Math.PI, 0, Math.PI]}>
          <mesh
            geometry={nodes.Body1_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_3.material
                : material
            }
          />
        </group>
        <group position={[0, 0, 4.74]}>
          <mesh
            geometry={nodes.Body1_4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_4.material
                : material
            }
          />
        </group>
        <mesh
          geometry={nodes.HGW15CC1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.HGW15CC1.material
              : material
          }
        />
      </group>
      <group
        position={[-1.86, 1, 73.39]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Mating_Slide_Plate.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Mating_Slide_Plate.material
              : material
          }
        />
      </group>
    </group>
  );
}

useGLTF.preload(UmbilicalGantryXAxisSlide_Model);
