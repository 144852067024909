/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import TierLightGantryX_Model from './TierLightGantryX.glb';

export default function TierLightGantryX(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(TierLightGantryX_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -1.85, -0.6]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Body1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? materials['Paint - Enamel Glossy (Dark Grey)']
              : material
          }
        />
      </group>
      <group position={[0, 0, -0.13]}>
        <group position={[1.59, -2.45, -5.57]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_1.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -2.77]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_2.material
                : material
            }
          />
        </group>
        <group position={[-17.32, 37.41, -30.9]} rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[18.91, 28.6, 39.86]}
            rotation={[0, -0.66, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_33.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_33.material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.34, 39.86]}
            rotation={[0, 0.77, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)'].material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.86, 39.86]}
            rotation={[Math.PI, -0.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin_1'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin_1'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)_1'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)_1'].material
                  : material
              }
            />
          </group>
          <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
            <mesh
              geometry={nodes.Body1_36.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_36.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[1.59, -2.45, -1.73]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_3.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -1.73]}>
          <mesh
            geometry={nodes.Body1_4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_4.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.82]}>
          <mesh
            geometry={nodes.Body1_5.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_5.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.51]}>
          <mesh
            geometry={nodes.Body1_6.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_6.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.19]}>
          <mesh
            geometry={nodes.Body1_7.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_7.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -1.73]}>
          <mesh
            geometry={nodes.Body1_8.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_8.material
                : material
            }
          />
        </group>
      </group>
      <group position={[-3.17, 0, -0.13]}>
        <group position={[1.59, -2.45, -5.57]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_9.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_9.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_1.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -2.77]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_10.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_10.material
                : material
            }
          />
        </group>
        <group position={[-17.32, 37.41, -30.9]} rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[18.91, 28.6, 39.86]}
            rotation={[0, -0.66, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_37.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_37.material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.34, 39.86]}
            rotation={[0, 0.77, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin_2'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin_2'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)_2'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)_2'].material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.86, 39.86]}
            rotation={[Math.PI, -0.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin_3'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin_3'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)_3'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)_3'].material
                  : material
              }
            />
          </group>
          <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
            <mesh
              geometry={nodes.Body1_40.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_40.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[1.59, -2.45, -1.73]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_11.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_11.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -1.73]}>
          <mesh
            geometry={nodes.Body1_12.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_12.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.82]}>
          <mesh
            geometry={nodes.Body1_13.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_13.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.51]}>
          <mesh
            geometry={nodes.Body1_14.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_14.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.19]}>
          <mesh
            geometry={nodes.Body1_15.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_15.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -1.73]}>
          <mesh
            geometry={nodes.Body1_16.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_16.material
                : material
            }
          />
        </group>
      </group>
      <group position={[0, 4.9, -0.13]}>
        <group position={[1.59, -2.45, -5.57]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_17.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_17.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_2.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -2.77]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_18.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_18.material
                : material
            }
          />
        </group>
        <group position={[-17.32, 37.41, -30.9]} rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[18.91, 28.6, 39.86]}
            rotation={[0, -0.66, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_41.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_41.material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.34, 39.86]}
            rotation={[0, 0.77, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin_4'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin_4'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)_4'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)_4'].material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.86, 39.86]}
            rotation={[Math.PI, -0.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin_5'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin_5'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)_5'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)_5'].material
                  : material
              }
            />
          </group>
          <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
            <mesh
              geometry={nodes.Body1_44.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_44.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[1.59, -2.45, -1.73]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_19.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_19.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -1.73]} />
        <group position={[1.59, -2.45, -0.82]} />
        <group position={[1.59, -2.45, -0.51]} />
        <group position={[1.59, -2.45, -0.19]} />
        <group position={[1.59, -2.45, -1.73]}>
          <mesh
            geometry={nodes.Body1_24.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_24.material
                : material
            }
          />
        </group>
      </group>
      <group position={[-3.17, 4.9, -0.13]}>
        <group position={[1.59, -2.45, -5.57]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_25.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_25.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_3.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -2.77]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_26.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_26.material
                : material
            }
          />
        </group>
        <group position={[-17.32, 37.41, -30.9]} rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[18.91, 28.6, 39.86]}
            rotation={[0, -0.66, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_45.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_45.material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.34, 39.86]}
            rotation={[0, 0.77, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin_6'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin_6'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)_6'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)_6'].material
                  : material
              }
            />
          </group>
          <group
            position={[18.91, 28.86, 39.86]}
            rotation={[Math.PI, -0.43, -Math.PI / 2]}>
            <mesh
              geometry={nodes['Body1_Stainless_Steel_-_Satin_7'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Stainless_Steel_-_Satin_7'].material
                  : material
              }
            />
            <mesh
              geometry={nodes['Body1_Paint_-_Enamel_Glossy_(Black)_7'].geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes['Body1_Paint_-_Enamel_Glossy_(Black)_7'].material
                  : material
              }
            />
          </group>
          <group position={[18.91, 28.55, 39.86]} rotation={[0, -0.45, 0]}>
            <mesh
              geometry={nodes.Body1_48.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_48.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[1.59, -2.45, -1.73]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_27.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_27.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -1.73]}>
          <mesh
            geometry={nodes.Body1_28.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_28.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.82]}>
          <mesh
            geometry={nodes.Body1_29.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_29.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.51]}>
          <mesh
            geometry={nodes.Body1_30.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_30.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -0.19]}>
          <mesh
            geometry={nodes.Body1_31.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_31.material
                : material
            }
          />
        </group>
        <group position={[1.59, -2.45, -1.73]}>
          <mesh
            geometry={nodes.Body1_32.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_32.material
                : material
            }
          />
        </group>
      </group>
      <mesh
        geometry={nodes.Small_Gantry_CartPlate_v7.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? materials['Plastic - Matte (Red)']
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(TierLightGantryX_Model);
