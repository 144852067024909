/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import UmbilicalMale_Model from './UmbilicalMale.glb';

export default function UmbilicalMale(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(UmbilicalMale_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes['2020_90_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2020_90_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2020_90'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2020_90'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2020_500'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2020_500'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2020_500_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2020_500_(1)'].material
            : material
        }
      />
      <group position={[0, -2, 0]} rotation={[0, 0, Math.PI]}>
        <group position={[-3.75, -1.5, 0]} rotation={[0, 0, -Math.PI]}>
          <group rotation={[0, Math.PI / 2, 0]}>
            <group position={[0, -0.51, 0]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_16.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_16.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_1.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_1.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body3_2.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body3_2.material
                    : material
                }
              />
            </group>
            <group position={[2, -0.51, 0]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_17.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_17.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_2.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_2.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body3_3.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body3_3.material
                    : material
                }
              />
            </group>
            <group position={[-2, -0.51, 0]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_18.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_18.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_3.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_3.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body3_4.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body3_4.material
                    : material
                }
              />
            </group>
            <mesh
              geometry={nodes.Body1_6.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_6.material
                  : material
              }
            />
            <mesh
              geometry={nodes.PogoHolder.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.PogoHolder.material
                  : material
              }
            />
          </group>
          <mesh
            geometry={nodes.Pogo_Holder.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Pogo_Holder.material
                : material
            }
          />
        </group>
        <group position={[3.75, -1.5, 0]} rotation={[0, 0, -Math.PI]}>
          <group rotation={[0, Math.PI / 2, 0]}>
            <group position={[0, -0.51, 0]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_19.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_19.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_4.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_4.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body3_5.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body3_5.material
                    : material
                }
              />
            </group>
            <group position={[2, -0.51, 0]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_20.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_20.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_5.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_5.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body3_6.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body3_6.material
                    : material
                }
              />
            </group>
            <group position={[-2, -0.51, 0]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_21.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_21.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_6.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_6.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body3_7.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body3_7.material
                    : material
                }
              />
            </group>
            <mesh
              geometry={nodes.Body1_7.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_7.material
                  : material
              }
            />
            <mesh
              geometry={nodes.PogoHolder_1.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.PogoHolder_1.material
                  : material
              }
            />
          </group>
          <mesh
            geometry={nodes.Pogo_Holder_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Pogo_Holder_1.material
                : material
            }
          />
        </group>
        <group position={[20, -1, 0]} rotation={[0, 0, -Math.PI]}>
          <group rotation={[0, 0, Math.PI]} />
          <group rotation={[0, 0, Math.PI]} />
          <mesh
            geometry={nodes.Water_Insert.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Water_Insert.material
                : material
            }
          />
        </group>
        <group position={[10, -1, 0]} rotation={[0, 0, -Math.PI]}>
          <group position={[0, 2.55, 0]} rotation={[Math.PI / 2, 0, 0]} />
          <group position={[0, 0.15, 0]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Backing.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Backing.material
                  : material
              }
            />
          </group>
          <mesh
            geometry={nodes.Electricty_Insert.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Electricty_Insert.material
                : material
            }
          />
        </group>
        <group position={[-10, -1, 0]} rotation={[0, 0, -Math.PI]}>
          <group position={[0, 2.55, 0]} rotation={[Math.PI / 2, 0, 0]} />
          <group position={[0, 0.15, 0]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Backing_1.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Backing_1.material
                  : material
              }
            />
          </group>
          <mesh
            geometry={nodes.Electricty_Insert_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Electricty_Insert_1.material
                : material
            }
          />
        </group>
        <group position={[-20, -1, 0]} rotation={[0, 0, -Math.PI]}>
          <group rotation={[0, 0, Math.PI]} />
          <mesh
            geometry={nodes.Water_Insert_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Water_Insert_1.material
                : material
            }
          />
        </group>
        <group position={[0, -3, 0]} rotation={[0, 0, -Math.PI]}>
          <mesh
            geometry={nodes.Camera_Holder.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Camera_Holder.material
                : material
            }
          />
          <mesh
            geometry={nodes.Camera_Holder_Leg.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Camera_Holder_Leg.material
                : material
            }
          />
          <mesh
            geometry={nodes['Camera_Holder_Leg_(1)'].geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes['Camera_Holder_Leg_(1)'].material
                : material
            }
          />
          <mesh
            geometry={nodes.Camera_Holder_Insert.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Camera_Holder_Insert.material
                : material
            }
          />
        </group>
      </group>
      <group position={[5, 0, -8]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Body1_8.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_8.material
              : material
          }
        />
      </group>
      <group position={[-5, 0, -8]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Body1_9.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_9.material
              : material
          }
        />
      </group>
      <group position={[0, -1, -8]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
        <mesh
          geometry={nodes.Body1_4.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_4.material
              : material
          }
        />
      </group>
      <group position={[-6, 6, -6]}>
        <mesh
          geometry={nodes.Wobbler.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Wobbler.material
              : material
          }
        />
      </group>
      <group position={[-6, 37, -6]}>
        <mesh
          geometry={nodes.Wobbler_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Wobbler_1.material
              : material
          }
        />
      </group>
      <group position={[6, 6, -6]} rotation={[0, 0, -Math.PI]}>
        <mesh
          geometry={nodes.Wobbler_2.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Wobbler_2.material
              : material
          }
        />
      </group>
      <group position={[6, 37, -6]} rotation={[0, 0, -Math.PI]}>
        <mesh
          geometry={nodes.Wobbler_3.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Wobbler_3.material
              : material
          }
        />
      </group>
      <mesh
        geometry={nodes['2020_390'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2020_390'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2020_390_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2020_390_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes.cbeam_130.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes.cbeam_130.material
            : material
        }
      />
      <mesh
        geometry={nodes['cbeam_130_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['cbeam_130_(1)'].material
            : material
        }
      />
      <group position={[26, 0, 7]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Body1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1.material
              : material
          }
        />
      </group>
      <group position={[26, 0, -7.4]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Body1_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_1.material
              : material
          }
        />
      </group>
      <group position={[-26, 0, -7]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh
          geometry={nodes.Body1_2.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_2.material
              : material
          }
        />
      </group>
      <group position={[-26, 0, 7.4]} rotation={[Math.PI / 2, 0, Math.PI]}>
        <mesh
          geometry={nodes.Body1_3.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_3.material
              : material
          }
        />
      </group>
      <group position={[0, 17.98, -4.7]} rotation={[0, 0, -Math.PI]}>
        <group position={[-2.5, -0.7, 0]} rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0.36, 0.72, 0.64]} rotation={[0, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_10.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_10.material
                  : material
              }
            />
          </group>
          <group position={[0.36, 0.72, 1.13]} rotation={[0, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_11.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_11.material
                  : material
              }
            />
          </group>
          <group position={[0.36, 0.72, 1.62]} rotation={[0, 0, Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_12.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_12.material
                  : material
              }
            />
          </group>
          <group
            position={[4.61, 0.72, 0.97]}
            rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_13.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_13.material
                  : material
              }
            />
          </group>
          <group
            position={[4.61, 0.72, 1.46]}
            rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_14.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_14.material
                  : material
              }
            />
          </group>
          <group
            position={[4.61, 0.72, 1.95]}
            rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_15.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_15.material
                  : material
              }
            />
          </group>
          <mesh
            geometry={nodes.Body1_5.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_5.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body3_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body3_1.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body4.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body5.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body5.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body6.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body6.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body7.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body7.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body8.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body8.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body9.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body9.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body10.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body10.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body11.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body11.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body12.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body12.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body13.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body13.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body14.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body14.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body15.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body15.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body16.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body16.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body17.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body17.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body18.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body18.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body19.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body19.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body20.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body20.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body21.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body21.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body22.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body22.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body23.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body23.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body24.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body24.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body25.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body25.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body26.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body26.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body27.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body27.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body28.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body28.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body29.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body29.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body30.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body30.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body31.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body31.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body32.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body32.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body33.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body33.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body34.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body34.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body35.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body35.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body36.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body36.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body37.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body37.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body38.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body38.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body39.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body39.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body40.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body40.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body41.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body41.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body42.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body42.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body43.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body43.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body44.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body44.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body45.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body45.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body46.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body46.material
                : material
            }
          />
        </group>
        <mesh
          geometry={nodes.Electronics_Plate.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? materials['Plastic - Matte (Red)']
              : material
          }
        />
      </group>
      <group position={[20, -1, 0]} rotation={[0, 0, -Math.PI]}>
        <mesh
          geometry={nodes.IngressInward.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.IngressInward.material
              : material
          }
        />
      </group>
      <group position={[-20, -1, 0]}>
        <mesh
          geometry={nodes.Body3.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body3.material
              : material
          }
        />
      </group>
      <group position={[27, -1, 0]} rotation={[-Math.PI, 0, 0]}>
        <mesh
          geometry={nodes.Male_Jet.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Male_Jet.material
              : material
          }
        />
      </group>
      <group position={[-27, -1, 0]} rotation={[0, 0, Math.PI]}>
        <mesh
          geometry={nodes.Male_Jet_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Male_Jet_1.material
              : material
          }
        />
      </group>
      <mesh
        geometry={nodes['2060_140'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_140'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2060_140_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_140_(1)'].material
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(UmbilicalMale_Model);
