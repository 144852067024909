import {
  combine,
  create,
  fullString,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import {
  Axis,
  SumpSubsystem,
  TierSubsystem,
  TowerSubsystem,
  TugSubsystem,
  urlCombine,
} from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import {
  convert,
  NumericalReading,
  Unit,
} from 'model/src/series/NumericalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { ValveStateReading } from 'model/src/series/ValveStateReading';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { cssVar } from '../../..';
import { HELVETIKER_REGULAR_FONT } from '../../../App';
import ErrorBoundary from '../../ErrorBoundary';
import {
  ControllerContext,
  ControllerContextType,
} from '../../farmviewer/context/controllercontext/ControllerContext';
import Box from '../common/Box';
import Text from '../common/Text';
import TowerBaseBottomPodBottom from './base/TowerBaseBottomPodBottom';
import TowerBaseBottomPodTop from './base/TowerBaseBottomPodTop';
import TowerBaseBridge from './base/TowerBaseBridge';
import TowerFillCycleFsmIcon from './fsm/TowerFillCycleFsmIcon';
import Sump from './sump/Sump';
import Tier, { TIER_HEIGHT } from './tier/Tier';
import Umbilical from './umbilical/Umbilical';

export const NEUTRAL_PWM = 2000;
export const PWM_RANGE = 1000;

export const CM_PER_INCH = 2.54;
export const ACTUATOR_STEPS_PER_INCH = 200;

export interface TowerProps {
  url: string;
  inspectionContext;
  dataMap;
  globals;
  positionX: Data<NumericalReading>;
  positionY: Data<NumericalReading>;
  positionZ: Data<NumericalReading>;
  objectMap;
  onClick;
  showMenu;
}

export default function Tower(props: TowerProps) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const [hovered, setHover] = useState(false);
  const group = useRef();

  const isOnlineData = props.dataMap.all.get(
    fullString(combine(toAddress(props.url), create([SeriesType.ONLINE])))
  ).data;
  const isOnline = isOnlineData[0].reading.value;
  const underInspection = props.inspectionContext.inspectionMap.get(props.url)
    .underInspection;

  const textOptions = {
    font: HELVETIKER_REGULAR_FONT.font,
    size: 5,
    height: 1,
  };

  const chooseColor = () => {
    if (underInspection) {
      return cssVar('--mapObjectLevel1_Selected');
    } else if (hovered) {
      return cssVar('--mapObjectLevel1_Hover');
    } else {
      return cssVar('--mapObject_Default');
    }
  };
  const color = chooseColor();

  useEffect(() => {
    props.objectMap.set(props.url, {
      url: props.url,
      model: group.current,
    });
    return () => {
      props.objectMap.delete(props.url);
    };
  });

  const determinePosition: (
    url: string,
    dataMap: any,
    positionX: NumericalReading,
    positionY: NumericalReading
  ) => { x: number; y: number } = (
    url: string,
    dataMap: any,
    positionX: NumericalReading,
    positionY: NumericalReading
  ) => {
    const baseConnectionUrl = combine(
      toAddress(props.url),
      create([TugSubsystem.CONNECTION])
    );
    const isConnectedUrl = join(baseConnectionUrl, SeriesType.IS_CONNECTED);

    const isConnected: boolean = dataMap.all.get(fullString(isConnectedUrl))
      .data[0].reading.value;

    if (isConnected) {
      const connectedIdUrl = join(baseConnectionUrl, SeriesType.CONNECTED_ID);
      const connectedId: string = dataMap.all.get(fullString(connectedIdUrl))
        .data[0].reading.value;

      if (connectedId.includes('.TUG.')) {
        const baseTugHomeUrl = join(toAddress(connectedId), SeriesType.HOME);
        const tugHomePositionXUrl = combine(
          baseTugHomeUrl,
          create([Axis.X, SeriesType.POSITION])
        );
        const tugHomePositionYUrl = combine(
          baseTugHomeUrl,
          create([Axis.Y, SeriesType.POSITION])
        );

        const tugHomePositionX: number = dataMap.all.get(
          fullString(tugHomePositionXUrl)
        ).data[0].reading.value;

        const tugHomePositionY: number = dataMap.all.get(
          fullString(tugHomePositionYUrl)
        ).data[0].reading.value;

        const deadReckoningXUrl =
          connectedId +
          urlCombine([SeriesType.DEAD_RECKONING, Axis.X, SeriesType.POSITION]);
        const deadReckoningX: number = dataMap.all.get(deadReckoningXUrl)
          .data[0].reading.value;

        const deadReckoningYUrl =
          connectedId +
          urlCombine([SeriesType.DEAD_RECKONING, Axis.Y, SeriesType.POSITION]);
        const deadReckoningY: number = dataMap.all.get(deadReckoningYUrl)
          .data[0].reading.value;

        const realPosition = {
          x: tugHomePositionX / convert(Unit.CM, Unit.MM) - deadReckoningX,
          y: tugHomePositionY / convert(Unit.CM, Unit.MM) + deadReckoningY,
        };
        return realPosition;
      }
    }
    const realPosition = {
      x: positionX.value / convert(Unit.CM, Unit.MM), // + 42,
      y: positionY.value / convert(Unit.CM, Unit.MM), // + 68,
    };
    return realPosition;
  };

  const realPosition: { x: number; y: number } = determinePosition(
    props.url,
    props.dataMap,
    props.positionX.data[0].reading,
    props.positionY.data[0].reading
  );

  // useEffect(() => {
  //   if (underInspection) {
  //     props.globals.centroidFinder();
  //   }
  // }, [realPosition.x, realPosition.y]);

  const tiers: any = [];
  const filteredTiers: any[] = useMemo(() => {
    return [...props.dataMap.tierMap].filter(tier => {
      return tier[0].startsWith(props.url);
    });
  }, [props.dataMap.tierMap, props.url]);

  filteredTiers.forEach(tier => {
    const tierId = tier[0].replace('.' + SeriesType.NAME, '');
    console.assert(tier[0].startsWith(props.url));

    const isOnlineData = props.dataMap.all.get(
      fullString(combine(toAddress(tierId), create([SeriesType.ONLINE])))
    ).data;
    const isOnline = isOnlineData[0].reading.value;

    const valveStateReading: ValveStateReading = props.dataMap.all.get(
      fullString(combine(toAddress(tierId), create([SeriesType.VALVE_STATE])))
    ).data[0].reading;

    const valveState = valveStateReading.value;

    const lightState = props.dataMap.all.get(
      fullString(combine(toAddress(tierId), create([SeriesType.AC_STATE, '1'])))
    ).data[0].reading.value;

    const doorPositionUrl = fullString(
      combine(
        toAddress(tierId),
        create([TierSubsystem.DOOR, SeriesType.POSITION])
      )
    );
    const doorPositionData: Data<NumericalReading> = props.dataMap.all.get(
      doorPositionUrl
    );
    const doorPositionMm = doorPositionData.data[0].reading.value;

    tiers.push(
      <Tier
        key={tierId}
        onClick={props.onClick}
        showMenu={props.showMenu}
        towerOnline={isOnline}
        inspectionContext={props.inspectionContext}
        objectMap={props.objectMap}
        globals={props.globals}
        parentUnderInspection={underInspection}
        parentHovered={hovered}
        tierData={{
          url: tierId,
          parentUrl: props.url,
          online: isOnline,
          valveState: valveState,
          lightState: lightState,
          doorPositionMm: doorPositionMm,
        }}
      />
    );
  });
  const onPointerOver = controllerContext.dragMute(event => {
    setHover(true);
    event.stopPropagation();
  }, controllerContext);

  return (
    <ErrorBoundary>
      <group
        ref={group}
        onClick={e => {
          if (!controllerContext.keys.has('Alt')) {
            props.onClick(props.url);
            e.stopPropagation();
          } else {
            props.showMenu(props.url);
            e.stopPropagation();
          }
        }}
        onContextMenu={e => {
          props.showMenu(props.url);
          e.stopPropagation();
        }}
        onPointerOver={onPointerOver}
        onPointerOut={event => {
          setHover(false);
          event.stopPropagation();
        }}
        position={[realPosition.x, realPosition.y, 5]}
        rotation={[
          0,
          0,
          (Math.PI * (180 - 0)) / // TODO(philipp): Use rotation from data.
            180,
        ]}>
        <ErrorBoundary>
          <Text
            position={[0, 0, 50]}
            rotation={[Math.PI / 2, 0, 0]}
            text={props.url}
            textOptions={textOptions}
            color={color}
          />
          <Suspense fallback={<Box />}>
            <TowerBaseBridge
              online={isOnline}
              parentUnderInspection={underInspection}
              parentHovered={hovered}
            />
            <group position={[-77, 0, 0]}>
              <TowerBaseBottomPodTop
                online={isOnline}
                parentUnderInspection={underInspection}
                parentHovered={hovered}
              />
              <TowerBaseBottomPodBottom
                online={isOnline}
                parentUnderInspection={underInspection}
                parentHovered={hovered}
              />
            </group>
            <group position={[77, 0, 0]}>
              <TowerBaseBottomPodTop
                online={isOnline}
                parentUnderInspection={underInspection}
                parentHovered={hovered}
              />
              <TowerBaseBottomPodBottom
                online={isOnline}
                parentUnderInspection={underInspection}
                parentHovered={hovered}
              />
            </group>
            <group position={[0, 73, 38.78]} rotation={[0, 0, Math.PI]}>
              <Sump //TODO(austin): rework sump to use the same format as other 3D components
                url={
                  props.url +
                  '.' +
                  TowerSubsystem.SUMP +
                  '.' +
                  SumpSubsystem.PUMP
                }
                dataMap={props.dataMap}
                globals={props.globals}
                inspectionContext={props.inspectionContext}
                objectMap={props.objectMap}
                onClick={props.onClick}
                showMenu={props.showMenu}
              />
            </group>
            <group position={[0, 0, 24.78]}>
              {tiers}
              <group
                position={[0, 0, (filteredTiers.length - 1) * TIER_HEIGHT]}>
                <Umbilical
                  url={props.url + '.' + TowerSubsystem.UMBILICAL}
                  online={isOnline}
                  onClick={props.onClick}
                  showMenu={props.showMenu}
                  inspectionContext={props.inspectionContext}
                  objectMap={props.objectMap}
                  dataMap={props.dataMap}
                  globals={props.globals}
                  parentUnderInspection={underInspection}
                  parentHovered={hovered}
                />
                <group position={[38, -80, 125]}>
                  <TowerFillCycleFsmIcon url={toAddress(props.url)} />
                </group>
              </group>
            </group>
          </Suspense>
        </ErrorBoundary>
      </group>
    </ErrorBoundary>
  );
}
