/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import TowerBaseBottomPodBottom_Model from './TowerBaseBottomPodBottom.glb';

export default function TowerBaseBottomPodBottom(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(TowerBaseBottomPodBottom_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-77, 0, 8.64]}>
        <group position={[77, 0, 19.8]}>
          <mesh
            geometry={nodes.Body1_50.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_50.material
                : material
            }
          />
        </group>
        <group position={[77, 0, 17.29]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_51.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_51.material
                : material
            }
          />
        </group>
        <group position={[77, 0, 22.14]} rotation={[-Math.PI / 2, 0, Math.PI]}>
          <mesh
            geometry={nodes.Body1_52.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_52.material
                : material
            }
          />
        </group>
        <group position={[76.99, -0.01, 14.23]}>
          <mesh
            geometry={nodes.Body1_53.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_53.material
                : material
            }
          />
        </group>
        <group position={[76.99, -0.01, 25.36]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_54.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_54.material
                : material
            }
          />
        </group>
        <group position={[76.99, -0.01, 14.08]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_55.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_55.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_3.material
                : material
            }
          />
        </group>
        <group position={[76.99, -0.01, 25.51]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_56.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_56.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_4.material
                : material
            }
          />
        </group>
        <group position={[0, -69, 0]}>
          <group position={[77, 0, 19.8]}>
            <mesh
              geometry={nodes.Body1_57.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_57.material
                  : material
              }
            />
          </group>
          <group position={[77, 0, 17.29]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_58.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_58.material
                  : material
              }
            />
          </group>
          <group
            position={[77, 0, 22.14]}
            rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_59.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_59.material
                  : material
              }
            />
          </group>
          <group position={[76.99, -0.01, 14.23]}>
            <mesh
              geometry={nodes.Body1_60.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_60.material
                  : material
              }
            />
          </group>
          <group position={[76.99, -0.01, 25.36]} rotation={[-Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_61.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_61.material
                  : material
              }
            />
          </group>
          <group
            position={[76.99, -0.01, 14.08]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_62.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_62.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_5.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_5.material
                  : material
              }
            />
          </group>
          <group
            position={[76.99, -0.01, 25.51]}
            rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_63.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_63.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_6.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_6.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[0, 69, 0]}>
          <group position={[77, 0, 19.8]}>
            <mesh
              geometry={nodes.Body1_64.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_64.material
                  : material
              }
            />
          </group>
          <group position={[77, 0, 17.29]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_65.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_65.material
                  : material
              }
            />
          </group>
          <group
            position={[77, 0, 22.14]}
            rotation={[-Math.PI / 2, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_66.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_66.material
                  : material
              }
            />
          </group>
          <group position={[76.99, -0.01, 14.23]}>
            <mesh
              geometry={nodes.Body1_67.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_67.material
                  : material
              }
            />
          </group>
          <group position={[76.99, -0.01, 25.36]} rotation={[-Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_68.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_68.material
                  : material
              }
            />
          </group>
          <group
            position={[76.99, -0.01, 14.08]}
            rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_69.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_69.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_7.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_7.material
                  : material
              }
            />
          </group>
          <group
            position={[76.99, -0.01, 25.51]}
            rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_70.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_70.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_8.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_8.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes.End_Plate.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.End_Plate.material
              : material
          }
        />
        <mesh
          geometry={nodes['End_Plate_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['End_Plate_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes.Spring_Assembly_Bottom_Plate.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Spring_Assembly_Bottom_Plate.material
              : material
          }
        />
      </group>
      <group position={[-77, 0, 0.63]}>
        <mesh
          geometry={nodes.No_Tip_Vertical.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.No_Tip_Vertical.material
              : material
          }
        />
        <mesh
          geometry={nodes['No_Tip_Vertical_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['No_Tip_Vertical_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['No_Tip_Vertical_(1)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['No_Tip_Vertical_(1)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['No_Tip_Vertical_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['No_Tip_Vertical_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(2)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(2)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(1)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(1)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes.Foot_Pad.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Foot_Pad.material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(2)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(2)'].material
              : material
          }
        />
      </group>
      <group position={[77, 0, 0.63]} rotation={[0, 0, Math.PI]}>
        <mesh
          geometry={nodes.No_Tip_Vertical_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.No_Tip_Vertical_1.material
              : material
          }
        />
        <mesh
          geometry={nodes['No_Tip_Vertical_(1)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['No_Tip_Vertical_(1)_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['No_Tip_Vertical_(1)_(1)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['No_Tip_Vertical_(1)_(1)_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['No_Tip_Vertical_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['No_Tip_Vertical_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(2)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(2)_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(1)_(1)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(1)_(1)_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes.Foot_Pad_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Foot_Pad_1.material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)_(1)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(2)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(2)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(2)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(2)_1'].material
              : material
          }
        />
      </group>
      <group position={[0, -69, 1.01]} rotation={[0, 0, -Math.PI / 4]}>
        <group position={[-4.3, -4.3, 1.9]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_71.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_71.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_9.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_9.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_72.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_72.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_73.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_73.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_74.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_74.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-4.3, 4.3, 1.9]} rotation={[0, 0, -Math.PI / 2]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_75.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_75.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_10.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_10.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_76.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_76.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_77.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_77.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_78.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_78.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[4.3, 4.3, 1.9]} rotation={[0, 0, Math.PI]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_79.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_79.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_11.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_11.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_80.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_80.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_81.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_81.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_82.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_82.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[0, 0, 6.83]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body3.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body4.material
                : material
            }
          />
        </group>
        <group position={[4.3, -4.3, 1.9]} rotation={[0, 0, Math.PI / 2]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_83.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_83.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_12.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_12.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_84.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_84.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_85.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_85.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_86.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_86.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[5.24, 0, 1.06]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_87.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_87.material
                : material
            }
          />
        </group>
        <group position={[5.24, 0, 1.4]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_88.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_88.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_13.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_13.material
                : material
            }
          />
        </group>
        <group position={[-10.48, 0, 0]}>
          <group position={[5.24, 0, 1.06]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_89.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_89.material
                  : material
              }
            />
          </group>
          <group position={[5.24, 0, 1.4]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_90.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_90.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_14.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_14.material
                  : material
              }
            />
          </group>
        </group>
        <group
          position={[0, 0, 0.63]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <group position={[0, 0, 12.17]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_1.material
                : material
            }
          />
        </group>
        <group position={[1.41, -5.66, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_91.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_91.material
                : material
            }
          />
        </group>
        <group position={[5.66, -1.41, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_92.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_92.material
                : material
            }
          />
        </group>
        <group position={[4.24, 0, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_93.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_93.material
                : material
            }
          />
        </group>
        <group position={[-4.24, 0, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_94.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_94.material
                : material
            }
          />
        </group>
        <group position={[0, 4.24, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_95.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_95.material
                : material
            }
          />
        </group>
        <group position={[0, -4.24, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_96.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_96.material
                : material
            }
          />
        </group>
        <group position={[-5.66, 1.41, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_97.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_97.material
                : material
            }
          />
        </group>
        <group position={[-1.41, 5.66, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_98.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_98.material
                : material
            }
          />
        </group>
        <mesh
          geometry={nodes.Pod_Top.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Pod_Top.material
              : material
          }
        />
        <mesh
          geometry={nodes.Pod_Bottom.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Pod_Bottom.material
              : material
          }
        />
        <mesh
          geometry={nodes.Pod_Middle.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Pod_Middle.material
              : material
          }
        />
      </group>
      <group position={[0, 0, 1.01]} rotation={[0, 0, -Math.PI / 4]}>
        <group position={[-4.3, -4.3, 1.9]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_99.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_99.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_15.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_15.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_100.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_100.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_101.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_101.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_102.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_102.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[-4.3, 4.3, 1.9]} rotation={[0, 0, -Math.PI / 2]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_103.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_103.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_16.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_16.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_104.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_104.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_105.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_105.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_106.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_106.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[4.3, 4.3, 1.9]} rotation={[0, 0, Math.PI]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_107.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_107.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_17.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_17.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_108.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_108.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_109.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_109.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_110.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_110.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[0, 0, 6.83]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_2.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_1.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body3_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body3_1.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body4_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body4_1.material
                : material
            }
          />
        </group>
        <group position={[4.3, -4.3, 1.9]} rotation={[0, 0, Math.PI / 2]}>
          <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_111.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_111.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_18.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_18.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes.Body1_112.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_112.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_113.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_113.material
                  : material
              }
            />
          </group>
          <group position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, -Math.PI]}>
            <mesh
              geometry={nodes.Body1_114.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_114.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[5.24, 0, 1.06]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_115.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_115.material
                : material
            }
          />
        </group>
        <group position={[5.24, 0, 1.4]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_116.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_116.material
                : material
            }
          />
          <mesh
            geometry={nodes.Body2_19.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body2_19.material
                : material
            }
          />
        </group>
        <group position={[-10.48, 0, 0]}>
          <group position={[5.24, 0, 1.06]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_117.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_117.material
                  : material
              }
            />
          </group>
          <group position={[5.24, 0, 1.4]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_118.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_118.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_20.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_20.material
                  : material
              }
            />
          </group>
        </group>
        <group
          position={[0, 0, 0.63]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <group position={[0, 0, 12.17]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_3.material
                : material
            }
          />
        </group>
        <group position={[1.41, -5.66, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_119.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_119.material
                : material
            }
          />
        </group>
        <group position={[5.66, -1.41, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_120.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_120.material
                : material
            }
          />
        </group>
        <group position={[4.24, 0, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_121.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_121.material
                : material
            }
          />
        </group>
        <group position={[-4.24, 0, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_122.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_122.material
                : material
            }
          />
        </group>
        <group position={[0, 4.24, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_123.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_123.material
                : material
            }
          />
        </group>
        <group position={[0, -4.24, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_124.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_124.material
                : material
            }
          />
        </group>
        <group position={[-5.66, 1.41, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_125.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_125.material
                : material
            }
          />
        </group>
        <group position={[-1.41, 5.66, 11.08]} rotation={[Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_126.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_126.material
                : material
            }
          />
        </group>
        <mesh
          geometry={nodes.Pod_Top_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Pod_Top_1.material
              : material
          }
        />
        <mesh
          geometry={nodes.Pod_Bottom_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Pod_Bottom_1.material
              : material
          }
        />
        <mesh
          geometry={nodes.Pod_Middle_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Pod_Middle_1.material
              : material
          }
        />
      </group>
      <group position={[0.03, 68.87, 1.45]} rotation={[0, 0, -Math.PI / 4]}>
        <group position={[-0.11, 0.07, -0.44]}>
          <group position={[-4.3, -4.3, 1.9]}>
            <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_127.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_127.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_21.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_21.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_128.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_128.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_129.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_129.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0, 0.38]}
              rotation={[Math.PI / 2, 0, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_130.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_130.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[-4.3, 4.3, 1.9]} rotation={[0, 0, -Math.PI / 2]}>
            <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_131.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_131.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_22.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_22.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
              <mesh
                geometry={nodes.Body1_132.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_132.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_133.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_133.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0, 0.38]}
              rotation={[Math.PI / 2, 0, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_134.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_134.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[4.3, 4.3, 1.9]} rotation={[0, 0, Math.PI]}>
            <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_135.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_135.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_23.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_23.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
              <mesh
                geometry={nodes.Body1_136.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_136.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_137.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_137.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0, 0.38]}
              rotation={[Math.PI / 2, 0, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_138.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_138.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[0, 0, 6.83]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_4.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_4.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_2.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_2.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body4_2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body4_2.material
                  : material
              }
            />
          </group>
          <group position={[4.3, -4.3, 1.9]} rotation={[0, 0, Math.PI / 2]}>
            <group position={[0, 0, -3.81]} rotation={[-Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_139.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_139.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_24.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_24.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, -1.27]} rotation={[-Math.PI, 0, Math.PI]}>
              <mesh
                geometry={nodes.Body1_140.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_140.material
                    : material
                }
              />
            </group>
            <group position={[0, 0, 0.89]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_141.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_141.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0, 0.38]}
              rotation={[Math.PI / 2, 0, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_142.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_142.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[5.24, 0, 1.06]} rotation={[0, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes.Body1_143.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_143.material
                  : material
              }
            />
          </group>
          <group position={[5.24, 0, 1.4]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_144.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_144.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_25.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_25.material
                  : material
              }
            />
          </group>
          <group position={[-10.48, 0, 0]}>
            <group position={[5.24, 0, 1.06]} rotation={[0, -Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_145.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_145.material
                    : material
                }
              />
            </group>
            <group position={[5.24, 0, 1.4]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes.Body1_146.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_146.material
                    : material
                }
              />
              <mesh
                geometry={nodes.Body2_26.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body2_26.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[0, 0, 0.63]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          />
          <group position={[0, 0, 12.17]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes.Body1_5.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_5.material
                  : material
              }
            />
          </group>
          <group position={[1.41, -5.66, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_147.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_147.material
                  : material
              }
            />
          </group>
          <group position={[5.66, -1.41, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_148.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_148.material
                  : material
              }
            />
          </group>
          <group position={[4.24, 0, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_149.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_149.material
                  : material
              }
            />
          </group>
          <group position={[-4.24, 0, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_150.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_150.material
                  : material
              }
            />
          </group>
          <group position={[0, 4.24, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_151.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_151.material
                  : material
              }
            />
          </group>
          <group position={[0, -4.24, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_152.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_152.material
                  : material
              }
            />
          </group>
          <group position={[-5.66, 1.41, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_153.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_153.material
                  : material
              }
            />
          </group>
          <group position={[-1.41, 5.66, 11.08]} rotation={[Math.PI, 0, 0]}>
            <mesh
              geometry={nodes.Body1_154.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_154.material
                  : material
              }
            />
          </group>
          <mesh
            geometry={nodes.Pod_Top_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Pod_Top_2.material
                : material
            }
          />
          <mesh
            geometry={nodes.Pod_Bottom_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Pod_Bottom_2.material
                : material
            }
          />
          <mesh
            geometry={nodes.Pod_Middle_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Pod_Middle_2.material
                : material
            }
          />
        </group>
      </group>
      <group position={[4, -34.5, 17.4]} rotation={[0, 0, Math.PI / 2]}>
        <group position={[0, -2.75, -29.24]}>
          <group position={[0, 2.45, 29.24]}>
            <mesh
              geometry={nodes.Body1_6.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_6.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 27.21]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_7.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_7.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 32.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_8.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_8.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 32.32]} rotation={[0, -Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_1'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_1'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_157.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_157.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_158.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_158.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_159.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_159.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_2'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_2'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_1'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_1'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_161.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_161.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[4.99, 0, 32.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_162.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_162.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_3'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_3'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_1'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_1'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_4'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_4'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_2'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_2'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_5'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_5'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_3'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_3'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_166.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_166.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_167.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_167.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_168.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_168.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_9.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_9.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, 1.25, 27.24]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_10.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_10.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 31.28]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_11.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_11.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 0, 26.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_169.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_169.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_6'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_6'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_2'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_2'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_7'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_7'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_4'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_4'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_8'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_8'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_5'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_5'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_173.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_173.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_174.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_174.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_175.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_175.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_12.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_12.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 26.16]} rotation={[0, Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_9'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_9'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_3'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_3'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_10'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_10'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_6'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_6'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_178.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_178.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_179.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_179.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_180.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_180.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_11'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_11'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_7'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_7'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_182.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_182.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 31.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_13.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_13.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 26.16]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_14.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_14.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 32.32]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_15.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_15.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 26.21]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_16.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_16.material
                  : material
              }
            />
          </group>
        </group>
      </group>
      <group position={[-4, -34.5, 17.4]} rotation={[0, 0, -Math.PI / 2]}>
        <group position={[0, -2.75, -29.24]}>
          <group position={[0, 2.45, 29.24]}>
            <mesh
              geometry={nodes.Body1_17.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_17.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 27.21]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_18.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_18.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 32.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_19.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_19.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 32.32]} rotation={[0, -Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_12'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_12'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_4'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_4'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_13'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_13'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_8'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_8'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_185.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_185.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_186.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_186.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_187.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_187.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_14'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_14'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_9'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_9'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_189.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_189.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[4.99, 0, 32.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_190.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_190.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_15'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_15'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_5'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_5'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_16'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_16'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_10'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_10'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_17'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_17'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_11'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_11'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_194.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_194.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_195.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_195.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_196.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_196.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_20.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_20.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, 1.25, 27.24]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_21.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_21.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 31.28]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_22.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_22.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 0, 26.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_197.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_197.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_18'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_18'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_6'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_6'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_19'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_19'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_12'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_12'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_20'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_20'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_13'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_13'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_201.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_201.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_202.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_202.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_203.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_203.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_23.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_23.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 26.16]} rotation={[0, Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_21'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_21'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_7'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_7'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_22'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_22'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_14'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_14'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_206.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_206.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_207.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_207.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_208.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_208.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_23'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_23'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_15'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_15'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_210.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_210.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 31.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_24.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_24.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 26.16]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_25.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_25.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 32.32]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_26.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_26.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 26.21]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_27.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_27.material
                  : material
              }
            />
          </group>
        </group>
      </group>
      <group position={[4, 34.5, 17.4]} rotation={[0, 0, Math.PI / 2]}>
        <group position={[0, -2.75, -29.24]}>
          <group position={[0, 2.45, 29.24]}>
            <mesh
              geometry={nodes.Body1_28.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_28.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 27.21]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_29.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_29.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 32.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_30.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_30.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 32.32]} rotation={[0, -Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_24'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_24'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_8'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_8'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_25'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_25'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_16'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_16'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_213.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_213.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_214.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_214.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_215.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_215.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_26'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_26'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_17'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_17'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_217.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_217.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[4.99, 0, 32.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_218.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_218.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_27'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_27'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_9'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_9'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_28'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_28'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_18'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_18'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_29'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_29'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_19'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_19'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_222.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_222.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_223.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_223.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_224.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_224.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_31.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_31.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, 1.25, 27.24]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_32.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_32.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 31.28]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_33.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_33.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 0, 26.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_225.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_225.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_30'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_30'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_10'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_10'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_31'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_31'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_20'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_20'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_32'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_32'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_21'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_21'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_229.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_229.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_230.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_230.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_231.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_231.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_34.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_34.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 26.16]} rotation={[0, Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_33'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_33'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_11'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_11'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_34'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_34'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_22'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_22'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_234.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_234.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_235.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_235.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_236.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_236.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_35'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_35'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_23'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_23'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_238.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_238.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 31.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_35.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_35.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 26.16]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_36.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_36.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 32.32]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_37.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_37.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 26.21]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_38.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_38.material
                  : material
              }
            />
          </group>
        </group>
      </group>
      <group position={[-4, 34.5, 17.4]} rotation={[0, 0, -Math.PI / 2]}>
        <group position={[0, -2.75, -29.24]}>
          <group position={[0, 2.45, 29.24]}>
            <mesh
              geometry={nodes.Body1_39.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_39.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 27.21]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_40.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_40.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 32.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_41.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_41.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 32.32]} rotation={[0, -Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_36'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_36'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_12'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_12'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_37'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_37'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_24'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_24'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_241.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_241.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, -Math.PI]}>
              <mesh
                geometry={nodes.Body1_242.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_242.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_243.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_243.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_38'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_38'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_25'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_25'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_245.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_245.material
                    : material
                }
              />
            </group>
          </group>
          <group position={[4.99, 0, 32.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_246.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_246.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_39'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_39'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_13'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_13'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_40'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_40'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_26'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_26'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_41'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_41'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_27'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_27'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_250.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_250.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_251.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_251.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_252.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_252.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_42.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_42.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, 1.25, 27.24]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_43.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_43.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 1.25, 31.28]} rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_44.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_44.material
                  : material
              }
            />
          </group>
          <group position={[4.99, 0, 26.24]}>
            <group position={[0, 1.25, 0]} rotation={[Math.PI / 2, 0, 2.34]}>
              <mesh
                geometry={nodes.Body1_253.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_253.material
                    : material
                }
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_42'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_42'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_14'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_14'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_43'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_43'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_28'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_28'].material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[-Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_44'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_44'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_29'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_29'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_257.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_257.material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_258.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_258.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_259.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_259.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 29.24]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_45.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_45.material
                  : material
              }
            />
          </group>
          <group position={[-4.99, 0, 26.16]} rotation={[0, Math.PI / 2, 0]}>
            <group rotation={[0, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_45'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_45'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Rubber_-_Hard_15'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Rubber_-_Hard_15'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.3, 0]} rotation={[0, 1.43, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_46'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_46'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_30'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_30'].material
                    : material
                }
              />
            </group>
            <group position={[0, -0.05, 0]} rotation={[0, 0.21, 0]}>
              <mesh
                geometry={nodes.Body1_262.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_262.material
                    : material
                }
              />
            </group>
            <group position={[0, -0.55, 0]} rotation={[0, -1.36, Math.PI]}>
              <mesh
                geometry={nodes.Body1_263.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_263.material
                    : material
                }
              />
            </group>
            <group
              position={[-0.08, 1.25, 0]}
              rotation={[Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes.Body1_264.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_264.material
                    : material
                }
              />
            </group>
            <group
              position={[0, 0.3, 0]}
              rotation={[Math.PI, -1.09, -Math.PI / 2]}>
              <mesh
                geometry={nodes['Body1_Aluminum_-_Satin_47'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Aluminum_-_Satin_47'].material
                    : material
                }
              />
              <mesh
                geometry={nodes['Body1_Coating_-_Black_Oxide_31'].geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes['Body1_Coating_-_Black_Oxide_31'].material
                    : material
                }
              />
            </group>
            <group position={[0, 0.55, 0]} rotation={[0, 0.89, 0]}>
              <mesh
                geometry={nodes.Body1_266.geometry}
                material={
                  props.renderingType === RenderingType.CadBased
                    ? nodes.Body1_266.material
                    : material
                }
              />
            </group>
          </group>
          <group
            position={[-4.99, 1.25, 31.28]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_46.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_46.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 26.16]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_47.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_47.material
                  : material
              }
            />
          </group>
          <group
            position={[-4.99, -1.25, 32.32]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_48.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_48.material
                  : material
              }
            />
          </group>
          <group
            position={[4.99, -1.25, 26.21]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh
              geometry={nodes.Body1_49.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_49.material
                  : material
              }
            />
          </group>
        </group>
      </group>
      <group position={[-77, 0, 0]}>
        <mesh
          geometry={nodes['2060_1500'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)_(1)_2'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)_(1)_2'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)_(2)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_80_(1)_(1)_2'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_80_(1)_(1)_2'].material
              : material
          }
        />
        <mesh
          geometry={nodes['CBeam_100_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['CBeam_100_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['CBeam_100_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['CBeam_100_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['CBeam_100_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['CBeam_100_(2)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['CBeam_100_(1)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['CBeam_100_(1)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['CBeam_100_(2)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['CBeam_100_(2)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2060_1500_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes.CBeam_100.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.CBeam_100.material
              : material
          }
        />
      </group>
    </group>
  );
}

useGLTF.preload(TowerBaseBottomPodBottom_Model);
