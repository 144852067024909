/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import LeftHandWheelModel from './LeftHandWheel.glb';
import materialFactory from './MaterialFactory';

export default function LeftHandWheel({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF(LeftHandWheelModel) as GLTF;

  const material: any = materialFactory.generateMaterial(
    props.parentUnderInspection,
    props.parentHovered,
    undefined,
    undefined,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Left_Hand_Wheel_v1">
        <group
          name="am-3340L_-_8in_MK_Mecanum_Left_Wheel_-_Rev11_v3"
          position={[9.68, 0, 0]}
          rotation={[0.26, 0, -Math.PI]}>
          <group
            name="left_ring"
            position={[0.72, 0, 0]}
            rotation={[0, -1.57, 0]}>
            <mesh name="mesh_0" geometry={nodes.mesh_0.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="left_ring_1"
            position={[8.17, 0, 0]}
            rotation={[-Math.PI / 6, Math.PI / 2, 0]}>
            <mesh name="mesh_1" geometry={nodes.mesh_1.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="812_roller"
            position={[-0.16, 1.65, 2.29]}
            rotation={[-1, -0.05, -0.78]}>
            <group name="812_roller_core" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_2" geometry={nodes.mesh_2.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_3" geometry={nodes.mesh_3.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_4" geometry={nodes.mesh_4.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="10-32_Nylock_Nut"
            position={[1.53, 8.88, 1.76]}
            rotation={[-2.62, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_5" geometry={nodes.mesh_5.geometry}>
              {material}
            </mesh>
          </group>
          <group name="Hub_Half" position={[0.88, 0, 0]}>
            <mesh name="mesh_6" geometry={nodes.mesh_6.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="Hub_Half_1"
            position={[8.01, 0, 0]}
            rotation={[-2.62, 0, -Math.PI]}>
            <mesh name="mesh_7" geometry={nodes.mesh_7.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="812_roller_1"
            position={[-0.16, 2.57, 1.16]}
            rotation={[-1.52, -0.05, -0.78]}>
            <group name="812_roller_core_1" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_8" geometry={nodes.mesh_8.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_1"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_9" geometry={nodes.mesh_9.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_1"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_10" geometry={nodes.mesh_10.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_2"
            position={[-0.16, 2.81, -0.28]}
            rotation={[-2.04, -0.05, -0.78]}>
            <group name="812_roller_core_2" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_11" geometry={nodes.mesh_11.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_2"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_12" geometry={nodes.mesh_12.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_2"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_13" geometry={nodes.mesh_13.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_3"
            position={[-0.16, 2.29, -1.65]}
            rotation={[-2.57, -0.05, -0.78]}>
            <group name="812_roller_core_3" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_14" geometry={nodes.mesh_14.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_3"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_15" geometry={nodes.mesh_15.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_3"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_16" geometry={nodes.mesh_16.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_4"
            position={[-0.16, 1.16, -2.57]}
            rotation={[-3.09, -0.05, -0.78]}>
            <group name="812_roller_core_4" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_17" geometry={nodes.mesh_17.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_4"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_18" geometry={nodes.mesh_18.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_4"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_19" geometry={nodes.mesh_19.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_5"
            position={[-0.16, -0.28, -2.81]}
            rotation={[2.67, -0.05, -0.78]}>
            <group name="812_roller_core_5" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_20" geometry={nodes.mesh_20.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_5"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_21" geometry={nodes.mesh_21.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_5"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_22" geometry={nodes.mesh_22.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_6"
            position={[-0.16, -1.65, -2.29]}
            rotation={[2.15, -0.05, -0.78]}>
            <group name="812_roller_core_6" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_23" geometry={nodes.mesh_23.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_6"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_24" geometry={nodes.mesh_24.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_6"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_25" geometry={nodes.mesh_25.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_7"
            position={[-0.16, -2.57, -1.16]}
            rotation={[1.62, -0.05, -0.78]}>
            <group name="812_roller_core_7" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_26" geometry={nodes.mesh_26.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_7"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_27" geometry={nodes.mesh_27.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_7"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_28" geometry={nodes.mesh_28.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_8"
            position={[-0.16, -2.81, 0.28]}
            rotation={[1.1, -0.05, -0.78]}>
            <group name="812_roller_core_8" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_29" geometry={nodes.mesh_29.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_8"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_30" geometry={nodes.mesh_30.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_8"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_31" geometry={nodes.mesh_31.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_9"
            position={[-0.16, -2.29, 1.65]}
            rotation={[0.57, -0.05, -0.78]}>
            <group name="812_roller_core_9" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_32" geometry={nodes.mesh_32.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_9"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_33" geometry={nodes.mesh_33.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_9"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_34" geometry={nodes.mesh_34.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_10"
            position={[-0.16, -1.16, 2.57]}
            rotation={[0.05, -0.05, -0.78]}>
            <group name="812_roller_core_10" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_35" geometry={nodes.mesh_35.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_10"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_36" geometry={nodes.mesh_36.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_10"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_37" geometry={nodes.mesh_37.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="812_roller_11"
            position={[-0.16, 0.28, 2.81]}
            rotation={[-0.47, -0.05, -0.78]}>
            <group name="812_roller_core_11" position={[2.46, 4.5, 5.69]}>
              <mesh name="mesh_38" geometry={nodes.mesh_38.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="812_roller_skin_11"
              position={[2.46, 4.5, 5.69]}
              rotation={[-0.09, 0, 0]}>
              <mesh name="mesh_39" geometry={nodes.mesh_39.geometry}>
                {material}
              </mesh>
            </group>
            <group
              name="Axle_tube_11"
              position={[-1.33, 4.5, 5.69]}
              rotation={[-2.98, Math.PI / 2, 0]}>
              <mesh name="mesh_40" geometry={nodes.mesh_40.geometry}>
                {material}
              </mesh>
            </group>
          </group>
          <group
            name="10-32_Nylock_Nut_1"
            position={[1.53, 8.57, -2.92]}
            rotation={[Math.PI, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_41" geometry={nodes.mesh_41.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_2"
            position={[1.53, 5.96, -6.81]}
            rotation={[2.62, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_42" geometry={nodes.mesh_42.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_3"
            position={[1.53, 1.76, -8.88]}
            rotation={[2.09, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_43" geometry={nodes.mesh_43.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_4"
            position={[1.53, -2.92, -8.57]}
            rotation={[Math.PI / 2, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_44" geometry={nodes.mesh_44.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_5"
            position={[1.53, -6.81, -5.96]}
            rotation={[Math.PI / 3, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_45" geometry={nodes.mesh_45.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_6"
            position={[1.53, -8.88, -1.76]}
            rotation={[Math.PI / 6, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_46" geometry={nodes.mesh_46.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_7"
            position={[1.53, -8.57, 2.92]}
            rotation={[0, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_47" geometry={nodes.mesh_47.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_8"
            position={[1.53, -5.96, 6.81]}
            rotation={[-Math.PI / 6, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_48" geometry={nodes.mesh_48.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_9"
            position={[1.53, -1.76, 8.88]}
            rotation={[-Math.PI / 3, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_49" geometry={nodes.mesh_49.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_10"
            position={[1.53, 2.92, 8.57]}
            rotation={[-Math.PI / 2, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_50" geometry={nodes.mesh_50.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32_Nylock_Nut_11"
            position={[1.53, 6.81, 5.96]}
            rotation={[-2.09, -Math.PI / 4, 0.7]}>
            <mesh name="mesh_51" geometry={nodes.mesh_51.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet"
            position={[1.36, 4.16, 4.15]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}>
            <mesh name="mesh_52" geometry={nodes.mesh_52.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_1"
            position={[7.53, 4.15, 4.16]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh name="mesh_53" geometry={nodes.mesh_53.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_2"
            position={[1.36, 5.68, 1.51]}
            rotation={[Math.PI / 3, 0, Math.PI / 2]}>
            <mesh name="mesh_54" geometry={nodes.mesh_54.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_3"
            position={[1.36, 5.67, -1.53]}
            rotation={[Math.PI / 6, 0, Math.PI / 2]}>
            <mesh name="mesh_55" geometry={nodes.mesh_55.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_4"
            position={[1.36, 4.15, -4.16]}
            rotation={[0, 0, Math.PI / 2]}>
            <mesh name="mesh_56" geometry={nodes.mesh_56.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_5"
            position={[1.36, 1.51, -5.68]}
            rotation={[-Math.PI / 6, 0, Math.PI / 2]}>
            <mesh name="mesh_57" geometry={nodes.mesh_57.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_6"
            position={[1.36, -1.53, -5.67]}
            rotation={[-Math.PI / 3, 0, Math.PI / 2]}>
            <mesh name="mesh_58" geometry={nodes.mesh_58.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_7"
            position={[1.36, -4.16, -4.15]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
            <mesh name="mesh_59" geometry={nodes.mesh_59.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_8"
            position={[1.36, -5.68, -1.51]}
            rotation={[-2.09, 0, Math.PI / 2]}>
            <mesh name="mesh_60" geometry={nodes.mesh_60.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_9"
            position={[1.36, -5.67, 1.53]}
            rotation={[-2.62, 0, Math.PI / 2]}>
            <mesh name="mesh_61" geometry={nodes.mesh_61.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_10"
            position={[1.36, -4.15, 4.16]}
            rotation={[Math.PI, 0, Math.PI / 2]}>
            <mesh name="mesh_62" geometry={nodes.mesh_62.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_11"
            position={[1.36, -1.51, 5.68]}
            rotation={[2.62, 0, Math.PI / 2]}>
            <mesh name="mesh_63" geometry={nodes.mesh_63.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_12"
            position={[1.36, 1.53, 5.67]}
            rotation={[2.09, 0, Math.PI / 2]}>
            <mesh name="mesh_64" geometry={nodes.mesh_64.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_13"
            position={[7.53, 5.67, 1.53]}
            rotation={[-2.09, 0, -Math.PI / 2]}>
            <mesh name="mesh_65" geometry={nodes.mesh_65.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_14"
            position={[7.53, 5.68, -1.51]}
            rotation={[-2.62, 0, -Math.PI / 2]}>
            <mesh name="mesh_66" geometry={nodes.mesh_66.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_15"
            position={[7.53, 4.16, -4.15]}
            rotation={[Math.PI, 0, -Math.PI / 2]}>
            <mesh name="mesh_67" geometry={nodes.mesh_67.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_16"
            position={[7.53, 1.53, -5.67]}
            rotation={[2.62, 0, -Math.PI / 2]}>
            <mesh name="mesh_68" geometry={nodes.mesh_68.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_17"
            position={[7.53, -1.51, -5.68]}
            rotation={[2.09, 0, -Math.PI / 2]}>
            <mesh name="mesh_69" geometry={nodes.mesh_69.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_18"
            position={[7.53, -4.15, -4.16]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
            <mesh name="mesh_70" geometry={nodes.mesh_70.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_19"
            position={[7.53, -5.67, -1.53]}
            rotation={[Math.PI / 3, 0, -Math.PI / 2]}>
            <mesh name="mesh_71" geometry={nodes.mesh_71.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_20"
            position={[7.53, -5.68, 1.51]}
            rotation={[Math.PI / 6, 0, -Math.PI / 2]}>
            <mesh name="mesh_72" geometry={nodes.mesh_72.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_21"
            position={[7.53, -4.16, 4.15]}
            rotation={[0, 0, -Math.PI / 2]}>
            <mesh name="mesh_73" geometry={nodes.mesh_73.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_22"
            position={[7.53, -1.53, 5.67]}
            rotation={[-Math.PI / 6, 0, -Math.PI / 2]}>
            <mesh name="mesh_74" geometry={nodes.mesh_74.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="187_pop_rivet_23"
            position={[7.53, 1.51, 5.68]}
            rotation={[-Math.PI / 3, 0, -Math.PI / 2]}>
            <mesh name="mesh_75" geometry={nodes.mesh_75.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213"
            position={[3.73, 0, -4.25]}
            rotation={[0, -1.57, 0]}>
            <mesh name="mesh_76" geometry={nodes.mesh_76.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_1"
            position={[5.16, 2.13, -3.68]}
            rotation={[-0.39, 1.57, 0]}>
            <mesh name="mesh_77" geometry={nodes.mesh_77.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_2"
            position={[3.73, 3.68, -2.13]}
            rotation={[Math.PI / 3, -Math.PI / 2, 0]}>
            <mesh name="mesh_78" geometry={nodes.mesh_78.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_3"
            position={[3.73, 3.68, 2.13]}
            rotation={[2.09, -Math.PI / 2, 0]}>
            <mesh name="mesh_79" geometry={nodes.mesh_79.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_4"
            position={[3.73, 0, 4.25]}
            rotation={[-Math.PI, -1.57, 0]}>
            <mesh name="mesh_80" geometry={nodes.mesh_80.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_5"
            position={[3.73, -3.68, 2.13]}
            rotation={[-2.09, -Math.PI / 2, 0]}>
            <mesh name="mesh_81" geometry={nodes.mesh_81.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_6"
            position={[3.73, -3.68, -2.13]}
            rotation={[-Math.PI / 3, -Math.PI / 2, 0]}>
            <mesh name="mesh_82" geometry={nodes.mesh_82.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_7"
            position={[5.16, 4.25, 0]}
            rotation={[0.65, Math.PI / 2, 0]}>
            <mesh name="mesh_83" geometry={nodes.mesh_83.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_8"
            position={[5.16, 2.13, 3.68]}
            rotation={[1.7, Math.PI / 2, 0]}>
            <mesh name="mesh_84" geometry={nodes.mesh_84.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_9"
            position={[5.16, -2.13, 3.68]}
            rotation={[2.75, 1.57, 0]}>
            <mesh name="mesh_85" geometry={nodes.mesh_85.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_10"
            position={[5.16, -4.25, 0]}
            rotation={[-2.49, Math.PI / 2, 0]}>
            <mesh name="mesh_86" geometry={nodes.mesh_86.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-24_Hex_Washer_Rolling_x_1_93882A213_11"
            position={[5.16, -2.13, -3.68]}
            rotation={[-1.44, Math.PI / 2, 0]}>
            <mesh name="mesh_87" geometry={nodes.mesh_87.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C"
            position={[7.7, 8.57, 3.26]}
            rotation={[-2.21, 0.54, 2.54]}>
            <mesh name="mesh_88" geometry={nodes.mesh_88.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_1"
            position={[7.7, 9.05, -1.46]}
            rotation={[-2.73, 0.54, 2.54]}>
            <mesh name="mesh_89" geometry={nodes.mesh_89.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_2"
            position={[7.7, 7.11, -5.79]}
            rotation={[3.03, 0.54, 2.54]}>
            <mesh name="mesh_90" geometry={nodes.mesh_90.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_3"
            position={[7.7, 3.26, -8.57]}
            rotation={[2.5, 0.54, 2.54]}>
            <mesh name="mesh_91" geometry={nodes.mesh_91.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_4"
            position={[7.7, -1.46, -9.05]}
            rotation={[1.98, 0.54, 2.54]}>
            <mesh name="mesh_92" geometry={nodes.mesh_92.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_5"
            position={[7.7, -5.79, -7.11]}
            rotation={[1.46, 0.54, 2.54]}>
            <mesh name="mesh_93" geometry={nodes.mesh_93.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_6"
            position={[7.7, -8.57, -3.26]}
            rotation={[0.93, 0.54, 2.54]}>
            <mesh name="mesh_94" geometry={nodes.mesh_94.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_7"
            position={[7.7, -9.05, 1.46]}
            rotation={[0.41, 0.54, 2.54]}>
            <mesh name="mesh_95" geometry={nodes.mesh_95.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_8"
            position={[7.7, -7.11, 5.79]}
            rotation={[-0.11, 0.54, 2.54]}>
            <mesh name="mesh_96" geometry={nodes.mesh_96.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_9"
            position={[7.7, -3.26, 8.57]}
            rotation={[-0.64, 0.54, 2.54]}>
            <mesh name="mesh_97" geometry={nodes.mesh_97.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_10"
            position={[7.7, 1.46, 9.05]}
            rotation={[-1.16, 0.54, 2.54]}>
            <mesh name="mesh_98" geometry={nodes.mesh_98.geometry}>
              {material}
            </mesh>
          </group>
          <group
            name="10-32x3500_shcs_HX-SHCS_019-32x0375x0375-C_11"
            position={[7.7, 5.79, 7.11]}
            rotation={[-1.68, 0.54, 2.54]}>
            <mesh name="mesh_99" geometry={nodes.mesh_99.geometry}>
              {material}
            </mesh>
          </group>
        </group>
        <group
          name="am-1523_#10_Fender_Washer_v1_(1)"
          position={[2.54, 0, 0]}
          rotation={[-Math.PI, 1.57, 0]}>
          <mesh name="mesh_100" geometry={nodes.mesh_100.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-4124_500_Hex_Hub_v2_(1)"
          position={[0.32, 0, 0]}
          rotation={[-Math.PI, 1.57, 0]}>
          <mesh name="mesh_101" geometry={nodes.mesh_101.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-3947-125_375H_x_125IN_Spacer_v2_(1)"
          rotation={[Math.PI, -1.57, 0]}
        />
        <group
          name="am-4124_500_Hex_Hub_v2_(1)_1"
          position={[10.16, 0, 0]}
          rotation={[0, -1.57, 0]}>
          <mesh name="mesh_102" geometry={nodes.mesh_102.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="500_Hex_Shaft_33425mm_v11"
          position={[9.88, 0, 0]}
          rotation={[0, 1.57, 0]}>
          <mesh name="mesh_103" geometry={nodes.mesh_103.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-1523_#10_Fender_Washer_v1_(1)_1"
          position={[7.78, 0, 0]}
          rotation={[-Math.PI, 1.57, 0]}>
          <mesh name="mesh_104" geometry={nodes.mesh_104.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="am-1523_#10_Fender_Washer_v1_(1)_2"
          position={[11.28, 0, 0]}
          rotation={[-Math.PI, 1.57, 0]}>
          <mesh name="mesh_105" geometry={nodes.mesh_105.geometry}>
            {material}
          </mesh>
        </group>
        <group
          name="500_Hex_Shaft_4in_v2"
          position={[-2.57, 0, 0]}
          rotation={[0, 1.57, 0]}>
          <mesh name="mesh_106" geometry={nodes.mesh_106.geometry}>
            {material}
          </mesh>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(LeftHandWheelModel);
