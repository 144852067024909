/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import UmbilicalGantryZAxisBeam_Model from './UmbilicalGantryZAxisBeam.glb';

export default function UmbilicalGantryZAxisBeam(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(UmbilicalGantryZAxisBeam_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[75, -55, 83.64]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Body1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1.material
              : material
          }
        />
      </group>
      <group position={[-80.95, -55, 32]}>
        <mesh
          geometry={nodes.Body1_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_1.material
              : material
          }
        />
      </group>
      <group position={[-80.04, -52, 32]} rotation={[0, 0, -Math.PI / 2]}>
        <mesh
          geometry={nodes.Body1_2.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_2.material
              : material
          }
        />
      </group>
      <group
        position={[-80.95, -55, 82]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Gantry_Beam_Connector_Plate.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Gantry_Beam_Connector_Plate.material
              : material
          }
        />
      </group>
      <group
        position={[-80.81, -55, 82]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Slide_End_Plate_R.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Slide_End_Plate_R.material
              : material
          }
        />
      </group>
      <group position={[80.04, -52, 32]} rotation={[0, 0, Math.PI / 2]}>
        <mesh
          geometry={nodes.Body1_3.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_3.material
              : material
          }
        />
      </group>
      <group position={[80.95, -55, 32]} rotation={[0, 0, -Math.PI]}>
        <mesh
          geometry={nodes.Body1_4.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_4.material
              : material
          }
        />
      </group>
      <group
        position={[72.28, -55, 82]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Slide_End_Plate_L.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Slide_End_Plate_L.material
              : material
          }
        />
      </group>
      <group
        position={[56.95, -55, 82]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes['Gantry_Beam_Connector_Plate_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Gantry_Beam_Connector_Plate_(1)'].material
              : material
          }
        />
      </group>
    </group>
  );
}

useGLTF.preload(UmbilicalGantryZAxisBeam_Model);
