/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import TierFrame_Model from './TierFrame.glb';

export default function TierFrame(props) {
  const group = useRef();
  const { nodes } = useGLTF(TierFrame_Model) as GLTF;
  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0, -4]}>
        <mesh
          geometry={nodes['2080_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['Cast_Corner_(2)_(1)_(3)_(3)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Cast_Corner_(2)_(1)_(3)_(3)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['Cast_Corner_(2)_(1)_(3)_(1)_(3)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Cast_Corner_(2)_(1)_(3)_(1)_(3)'].material
              : material
          }
        />
        <mesh
          geometry={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(3)'].geometry
          }
          material={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(3)'].material
          }
        />
        <mesh
          geometry={nodes['Cast_Corner_(2)_(1)_(1)_(1)_(3)_(4)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Cast_Corner_(2)_(1)_(1)_(1)_(3)_(4)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['Cast_Corner_(2)_(1)_(3)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Cast_Corner_(2)_(1)_(3)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['Cast_Corner_(2)_(1)_(1)_(1)_(3)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Cast_Corner_(2)_(1)_(1)_(1)_(3)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(1)_(2)'].geometry
          }
          material={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(1)_(2)'].material
          }
        />
        <mesh
          geometry={nodes['Cast_Corner_(2)_(1)_(3)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Cast_Corner_(2)_(1)_(3)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2060_1500_(4)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2060_1500_(4)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2060_1500_(4)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2060_1500_(4)_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(2)'].material
              : material
          }
        />
        <mesh
          geometry={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(1)_(2)_(1)']
              .geometry
          }
          material={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(1)_(2)_(1)']
              .material
          }
        />
        <mesh
          geometry={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(1)_(2)_(1)_(1)']
              .geometry
          }
          material={
            nodes['Cast_Corner_(2)_(1)_(1)_(1)_(1)_(1)_(1)_(1)_(2)_(1)_(1)']
              .material
          }
        />
        <mesh
          geometry={nodes['2080_(1)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_(1)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2060_1500_(4)_(3)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2060_1500_(4)_(3)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)_(1)'].material
              : material
          }
        />
      </group>
      <group position={[0, 0, 1.37]}>
        <group position={[76, 66, 27.08]} rotation={[Math.PI, 0, 0]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_1.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_1.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_1.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_1.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[76.02, 7, 72.18]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_2.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_2.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_3.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_3.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_3.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_3.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_1.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_1.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes['2060_1500_(4)_(3)_(1)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)_(1)_1'].material
              : material
          }
        />
      </group>
      <group position={[0, 0, 1.37]} rotation={[0, 0, Math.PI]}>
        <group position={[76, 66, 27.08]} rotation={[Math.PI, 0, 0]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_4.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_4.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_4.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_4.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_5.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_5.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_5.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_5.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_2.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_2.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[76.02, 7, 72.18]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_6.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_6.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_6.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_6.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_7.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_7.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_7.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_7.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_3.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_3.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes['2060_1500_(4)_(3)_(1)_2'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)_(1)_2'].material
              : material
          }
        />
      </group>
      <group position={[152, 0, 1.37]} rotation={[0, 0, -Math.PI]}>
        <group position={[76, 66, 27.08]} rotation={[Math.PI, 0, 0]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_8.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_8.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_8.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_8.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_9.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_9.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_9.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_9.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_4.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_4.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[76.02, 7, 72.18]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_10.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_10.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_10.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_10.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_11.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_11.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_11.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_11.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_5.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_5.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes['2060_1500_(4)_(3)_(1)_3'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)_(1)_3'].material
              : material
          }
        />
      </group>
      <group position={[-152, 0, 1.37]}>
        <group position={[76, 66, 27.08]} rotation={[Math.PI, 0, 0]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_12.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_12.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_12.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_12.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_13.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_13.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_13.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_13.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_6.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_6.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[76.02, 7, 72.18]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_14.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_14.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_14.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_14.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_15.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_15.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_15.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_15.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_7.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_7.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes['2060_1500_(4)_(3)_(1)_4'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)_(1)_4'].material
              : material
          }
        />
      </group>
      <group position={[0, 150, 1.37]} rotation={[0, 0, -Math.PI / 2]}>
        <group position={[76, 66, 27.08]} rotation={[Math.PI, 0, 0]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_16.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_16.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_16.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_16.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_17.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_17.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_17.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_17.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_8.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_8.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[76.02, 7, 72.18]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_18.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_18.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_18.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_18.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_19.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_19.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_19.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_19.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_9.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_9.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes['2060_1500_(4)_(3)_(1)_5'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)_(1)_5'].material
              : material
          }
        />
      </group>
      <group position={[0, -2, 1.37]} rotation={[0, 0, Math.PI / 2]}>
        <group position={[76, 66, 27.08]} rotation={[Math.PI, 0, 0]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_20.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_20.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_20.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_20.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_21.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_21.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_21.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_21.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_10.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_10.material
                  : material
              }
            />
          </group>
        </group>
        <group position={[76.02, 7, 72.18]}>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_22.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_22.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_22.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_22.material
                  : material
              }
            />
          </group>
          <group position={[0, 0.86, 0.41]} rotation={[0.9, 0, 0]}>
            <mesh
              geometry={nodes.Body1_23.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body1_23.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body2_23.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body2_23.material
                  : material
              }
            />
            <mesh
              geometry={nodes.Body3_11.geometry}
              material={
                props.renderingType === RenderingType.CadBased
                  ? nodes.Body3_11.material
                  : material
              }
            />
          </group>
        </group>
        <mesh
          geometry={nodes['2060_1500_(4)_(3)_(1)_6'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2060_1500_(4)_(3)_(1)_6'].material
              : material
          }
        />
      </group>
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(2)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(2)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(3)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(3)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(4)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(4)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(5)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(5)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(6)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(6)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(7)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(7)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(8)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(8)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(9)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(9)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['20x40_1500_(1)_(1)_(1)_(10)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['20x40_1500_(1)_(1)_(1)_(10)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2040_1500'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2040_1500'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2040_1500_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2040_1500_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2040_1500_(2)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2040_1500_(2)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2060_1500'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_1500'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2060_1500_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_1500_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2060_1500_(2)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_1500_(2)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2060_1500_(1)_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_1500_(1)_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2080_1500'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2080_1500'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2080_600'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2080_600'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2080_600_(2)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2080_600_(2)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2080_600_(3)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2080_600_(3)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2060_600'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_600'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2060_600_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2060_600_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2080_600_(4)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2080_600_(4)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2080_600_(4)_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2080_600_(4)_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['2080_600_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['2080_600_(1)'].material
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(TierFrame_Model);
