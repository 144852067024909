import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import { combine, create } from 'model/src/common/CloudProduceAddressUtility';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { FillCycleState } from 'model/src/status/FillCycleState';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

import useSvg from '../../../../common/useSvg';
import { useDataMap } from '../../../farmviewer/context/datamapcontext/useDataMap';

const DEPTH_CM = 2;

export type TowerWaterFsmIconProps = {
  url: CloudProduceAddress;
};

const TowerFillCycleFsmIcon = (props: TowerWaterFsmIconProps) => {
  const fsmState = useDataMap(
    combine(props.url, create([SeriesType.FILL_CYCLE_STATE]))
  );

  const groupRef = useRef<THREE.Group>(new THREE.Group());

  const WaterCycleSvgMesh: THREE.Mesh = useSvg(
    '/svg/tower/WaterCycle.svg',
    DEPTH_CM,
    (mesh: THREE.Mesh) => {
      mesh.rotateZ(0);
      mesh.rotateX(-Math.PI / 2);
      mesh.material = new THREE.MeshPhongMaterial({ color: 'blue' });
      mesh.scale.set(0.275, 0.275);
    },
    []
  );

  useEffect(() => {
    if (groupRef.current && WaterCycleSvgMesh) {
      groupRef.current.clear();
      if (
        fsmState &&
        fsmState.value &&
        fsmState.value === FillCycleState.CYCLING
      )
        groupRef.current.add(WaterCycleSvgMesh);
    }
  }, [fsmState, WaterCycleSvgMesh]);

  return React.createElement('primitive', {
    object: groupRef.current,
  });
};

export default TowerFillCycleFsmIcon;
