import { LiteralTokens } from 'model/src/common/Regex';
import { AlarmLevel, AlarmTypes } from 'model/src/common/Systems';
import { Unit } from 'model/src/series/NumericalReading';
import React, { useContext } from 'react';
import { DataMap } from 'services/src/pagedataintake/PageDataIntake';
import styled from 'styled-components';

import { cssVar } from '../..';
import {
  DesktopContext,
  DesktopContextType
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';

const INDICATOR_DEBUG = false;
const INDICATOR_DEBUG_STEP_SIZE = 30;

export interface AnalogIndicatorRange {
  highNormal?: number;
  lowNormal?: number;

  highBoundary: number;
  lowBoundary: number;
  lowCritical?: number;
  highCritical?: number;
  lowWarning?: number;
  highWarning?: number;

  unit: Unit;
}

export interface DataStream {
  stream: number[];
  unit: Unit;
}

export type DataStreamInput = {
  dataMap: DataMap;
  listenUrl: string;
};

export type AnalogIndicatorDisplayProps = {
  title?: string;
  scale?: number;
  range: AnalogIndicatorRange;
  current: number;
};

export type AnalogIndicatorProps = {
  title?: string;
  scale?: number;
  url: string;
};

const getInvertedValueAsString = (value: number) => {
  const stringValue =
    value > 0 ? '-' + value : value.toString().replace('-', '');
  return stringValue;
};

const allEqual = array => array.every(v => v === array[0]);

export const getIndicatorRange = (dataMap: DataMap, listenUrl: string) => {
  const boundaryArrays = {
    highBoundary: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.HIGH_BOUNDARY
    ),
    lowBoundary: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.LOW_BOUNDARY
    ),
    lowCritical: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.LOW_CRITICAL
    ),
    highCritical: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.HIGH_CRITICAL
    ),
    highNormal: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.HIGH_NORMAL
    ),
    lowNormal: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.LOW_NORMAL
    ),
    lowWarning: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.LOW_WARNING
    ),
    highWarning: dataMap.all.get(
      listenUrl +
        LiteralTokens.PERIOD +
        AlarmTypes.NUMERIC_ALARM +
        LiteralTokens.PERIOD +
        AlarmLevel.HIGH_WARNING
    ),
  };

  const units: Unit[] = [];
  Object.keys(boundaryArrays).forEach(key => {
    if (boundaryArrays[key]) {
      units.push(
        boundaryArrays[key].data[boundaryArrays[key].data.length - 1].reading
          .unit
      );
    }
  });
  return {
    highNormal: boundaryArrays.highNormal
      ? boundaryArrays.highNormal.data[
          boundaryArrays.highNormal.data.length - 1
        ].reading.value
      : undefined,
    lowNormal: boundaryArrays.lowNormal
      ? boundaryArrays.lowNormal.data[boundaryArrays.lowNormal.data.length - 1]
          .reading.value
      : undefined,

    highBoundary:
      boundaryArrays.highBoundary.data[
        boundaryArrays.highBoundary.data.length - 1
      ].reading.value,
    lowBoundary:
      boundaryArrays.lowBoundary.data[
        boundaryArrays.lowBoundary.data.length - 1
      ].reading.value,

    lowCritical: boundaryArrays.lowCritical
      ? boundaryArrays.lowCritical.data[
          boundaryArrays.lowCritical.data.length - 1
        ].reading.value
      : undefined,
    highCritical: boundaryArrays.highCritical
      ? boundaryArrays.highCritical.data[
          boundaryArrays.highCritical.data.length - 1
        ].reading.value
      : undefined,
    lowWarning: boundaryArrays.lowWarning
      ? boundaryArrays.lowWarning.data[
          boundaryArrays.lowWarning.data.length - 1
        ].reading.value
      : undefined,
    highWarning: boundaryArrays.highWarning
      ? boundaryArrays.highWarning.data[
          boundaryArrays.highWarning.data.length - 1
        ].reading.value
      : undefined,

    unit: units[0] as Unit,
  };
};

const DisplayBar = styled.rect`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  fill: var(--analogIndicatorAcceptableRange);
  stroke-width: var(--analogIndicatorStrokeWidth);
  stroke: var(--analogIndicatorStrokeColor);
  x: var(--analogIndicatorXOffset);
  y: ${props => props.y};
`;

const DesiredRangeBar = styled.rect`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  y: ${props => props.y};
  fill: var(--analogIndicatorDesiredRange);
  stroke-width: var(--analogIndicatorStrokeWidth);
  stroke: var(--analogIndicatorStrokeColor);
  ${INDICATOR_DEBUG
    ? 'x: ' + INDICATOR_DEBUG_STEP_SIZE * 3
    : 'x: var(--analogIndicatorXOffset);'}
`;

const TopWarningBar = styled.rect`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  y: ${props => props.y};
  fill: ${props =>
    props.active
      ? 'var(--analogIndicatorWarningRange_active);'
      : 'var(--analogIndicatorWarningRange_inactive);'}
  stroke-width: var(--analogIndicatorStrokeWidth);
  stroke: var(--analogIndicatorStrokeColor);
  ${
    INDICATOR_DEBUG
      ? 'x: ' + INDICATOR_DEBUG_STEP_SIZE * 2
      : 'x: var(--analogIndicatorXOffset);'
  }
`;
const BottomWarningBar = styled.rect`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  y: ${props => props.y};
  fill: ${props =>
    props.active
      ? 'var(--analogIndicatorWarningRange_active);'
      : 'var(--analogIndicatorWarningRange_inactive);'}
  stroke-width: var(--analogIndicatorStrokeWidth);
  stroke: var(--analogIndicatorStrokeColor);
  ${
    INDICATOR_DEBUG
      ? 'x: ' + INDICATOR_DEBUG_STEP_SIZE * 2
      : 'x: var(--analogIndicatorXOffset);'
  }
`;

const TopErrorBar = styled.rect`
height: ${props => props.height}px;
y: ${props => props.y};
width: ${props => props.displayWidth}px;
fill: ${props =>
  props.active
    ? 'var(--analogIndicatorErrorRange_active)'
    : 'var(--analogIndicatorErrorRange_inactive)'};
stroke-width: var(--analogIndicatorStrokeWidth);
stroke: var(--analogIndicatorStrokeColor);
  ${
    INDICATOR_DEBUG
      ? 'x: ' + INDICATOR_DEBUG_STEP_SIZE * 1
      : 'x: var(--analogIndicatorXOffset);'
  }
  y: ${props => props.y};
`;
const BottomErrorBar = styled.rect`
  height: ${props => props.height}px;
  y: ${props => props.y};
  width: ${props => props.displayWidth}px;
  fill: ${props =>
    props.active
      ? 'var(--analogIndicatorErrorRange_active)'
      : 'var(--analogIndicatorErrorRange_inactive)'};
  stroke-width: var(--analogIndicatorStrokeWidth);
  stroke: var(--analogIndicatorStrokeColor);
  ${INDICATOR_DEBUG
    ? 'x: ' + INDICATOR_DEBUG_STEP_SIZE * 1
    : 'x: var(--analogIndicatorXOffset);'}
`;

const Needle = props => {
  const needleYValue0 = getInvertedValueAsString(props.needlePositionYOffset);
  const needleYValue1 = getInvertedValueAsString(
    props.needlePositionYOffset + +cssVar('--analogIndicatorNeedleHeight')
  );
  const needleYValue2 = getInvertedValueAsString(
    props.needlePositionYOffset + +cssVar('--analogIndicatorNeedleWidth')
  );

  return (
    <>
      <g transform="scale(1,-1)">
        <polygon
          points={
            // TODO(austin) tokenize needle shape
            '15, ' +
            needleYValue0 +
            ' 15, ' +
            needleYValue1 +
            ' 30, ' +
            needleYValue2
          }
          fill={cssVar('--analogIndicatorNeedleColor')}
        />

        <text
          x="0"
          y={-props.needlePositionYOffset}
          textAnchor="left"
          fill={cssVar('--black')}
          fontSize={cssVar('--analogIndicatorNeedleFontSize')}>
          {props.current?.toString()}
        </text>
      </g>
    </>
  );
};

const AnalogIndicatorDisplay = (props: AnalogIndicatorDisplayProps) => {
  const indicatorScale = props.scale ? props.scale : 1;
  const displayWidth: number = +cssVar('--analogIndicatorDisplayWidth');
  const errorBarHeight: number = +cssVar('--analogIndicatorErrorBarHeight');
  const halfNeedleOffset: number = +cssVar('--analogIndicatorDisplayWidth');
  const indicatorBarHeight: number =
    +cssVar('--analogIndicatorHeight') * indicatorScale;
  const maxValueDisplayed = props.range.highCritical
    ? props.range.highCritical
    : props.range.highBoundary;
  const minValueDisplayed = props.range.lowCritical
    ? props.range.lowCritical
    : props.range.lowBoundary;
  const scalingFactor =
    indicatorBarHeight / (maxValueDisplayed - minValueDisplayed);
  const svgWindowHeight = indicatorBarHeight + errorBarHeight * 2;

  const needlePositionYOffset =
    Math.min(
      Math.max(props.current - minValueDisplayed, 0),
      maxValueDisplayed - minValueDisplayed
    ) *
      scalingFactor +
    errorBarHeight -
    halfNeedleOffset;

  return (
    <svg
      style={{
        height: svgWindowHeight,
        width: displayWidth * 4,
      }}>
      <g transform={'translate(0,' + svgWindowHeight + ')'}>
        <g transform="scale(1,-1)">
          <DisplayBar
            height={indicatorBarHeight}
            width={displayWidth}
            y={errorBarHeight}
          />
          {props.range.highWarning ? (
            <TopWarningBar
              active={props.current >= props.range.highWarning}
              height={
                (maxValueDisplayed - props.range.highWarning) * scalingFactor
              }
              width={displayWidth}
              y={
                errorBarHeight +
                (props.range.highWarning - minValueDisplayed) * scalingFactor
              }
            />
          ) : (
            ''
          )}
          {props.range.highCritical ? (
            <TopErrorBar
              active={props.current >= props.range.highCritical}
              height={errorBarHeight}
              width={displayWidth}
              y={indicatorBarHeight + errorBarHeight}
            />
          ) : (
            ''
          )}
          {props.range.highNormal && props.range.lowNormal && (
            <DesiredRangeBar
              height={
                (props.range.highNormal - props.range.lowNormal) * scalingFactor
              }
              width={displayWidth}
              y={
                (props.range.lowNormal - minValueDisplayed) * scalingFactor +
                errorBarHeight
              }
            />
          )}
          {props.range.lowWarning ? (
            <BottomWarningBar
              active={props.current <= props.range.lowWarning}
              height={
                (props.range.lowWarning - minValueDisplayed) * scalingFactor
              }
              width={displayWidth}
              y={errorBarHeight}
            />
          ) : (
            ''
          )}
          {props.range.lowCritical ? (
            <BottomErrorBar
              active={props.current < props.range.lowCritical}
              displayWidth={displayWidth}
              height={errorBarHeight}
              y={0}
            />
          ) : (
            ''
          )}
          <Needle
            needlePositionYOffset={needlePositionYOffset}
            current={props.current}
          />
        </g>
      </g>
    </svg>
  );
};
const isValidNumericAlarm = (currentReading, range) => {
  if (!currentReading) {
    return false;
  } else if (!range) {
    return false;
  }
  return true;
};
const AnalogIndicator = (props: AnalogIndicatorProps) => {
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  let currentReading;
  let range;
  if (props.url) {
    currentReading = desktopContext.dataMap.all.get(props.url).data[0].reading;
    range = getIndicatorRange(desktopContext.dataMap, props.url);
    if (!isValidNumericAlarm(currentReading, range)) {
      return <></>;
    }
  } else {
    console.log('bad url');
    return <></>;
  }
  return (
    <div style={{ display: 'inline' }}>
      <AnalogIndicatorDisplay
        title={props.title}
        current={currentReading.value}
        range={range}
        scale={props.scale}
      />
    </div>
  );
};

export default AnalogIndicator;
