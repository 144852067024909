import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import { combine, create } from 'model/src/common/CloudProduceAddressUtility';
import { AtomicReading } from 'model/src/dataflowprotocol/AtomicReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { FillCycleState } from 'model/src/status/FillCycleState';
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { useInterval } from 'usehooks-ts';
import util from 'util';

import { cssVar } from '../../../../..';
import useSvg from '../../../../../common/useSvg';
import { useDataMap } from '../../../../farmviewer/context/datamapcontext/useDataMap';

const DEPTH_CM = 2;

export type TierFillCycleFsmIcon = {
  url: CloudProduceAddress;
};

const TierFillCycleFsmIcon = (props: TierFillCycleFsmIcon) => {
  const fsmState = useDataMap(
    combine(
      props.url,
      create([SeriesType.FILL_CYCLE, SeriesType.FILL_CYCLE_STATE])
    )
  );

  const groupRef = useRef<THREE.Group>(new THREE.Group());

  const TierCycleSvgMesh: THREE.Mesh = useSvg(
    getSvgUrl(fsmState),
    DEPTH_CM,
    (mesh: THREE.Mesh) => {
      if (mesh) {
        mesh.rotateZ(0);
        mesh.rotateX(-Math.PI / 2);
        mesh.material = new THREE.MeshPhongMaterial({
          color: cssVar('--systemBlue'),
        });
        mesh.scale.set(1, 1);
        mesh.position.set(0, 0, 0);
      }
      if (!isDisplayable(fsmState)) {
        mesh.scale.set(0, 0);
        mesh.material.opacity = 0;
        mesh.material.transparent = true;
      }
    },
    [fsmState]
  );

  useEffect(() => {
    if (groupRef.current && TierCycleSvgMesh) {
      groupRef.current.clear();
      groupRef.current.add(TierCycleSvgMesh);

      const startParameters = { scale: 1, position: 0 };
      new TWEEN.Tween(startParameters)
        .to({ scale: 1.5, position: 5 }, 250)
        .easing(TWEEN.Easing.Linear.None)
        .onUpdate(() => {
          if (TierCycleSvgMesh) {
            TierCycleSvgMesh.scale.set(
              startParameters.scale,
              startParameters.scale,
              startParameters.scale
            );
            TierCycleSvgMesh.position.set(
              -startParameters.position,
              0,
              startParameters.position
            );
          }
        })
        .onComplete(() => {
          new TWEEN.Tween(startParameters)
            .to({ scale: 1, position: 0 }, 750)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
              if (TierCycleSvgMesh) {
                TierCycleSvgMesh.scale.set(
                  startParameters.scale,
                  startParameters.scale,
                  startParameters.scale
                );
              }
              TierCycleSvgMesh.position.set(
                -startParameters.position,
                0,
                startParameters.position
              );
            })
            .start();
        })
        .start();
    }
  }, [fsmState, TierCycleSvgMesh]);

  return React.createElement('primitive', {
    object: groupRef.current,
  });
};

const isDisplayable = (fsmState: AtomicReading<FillCycleState>) => {
  if (!fsmState || !fsmState.value) {
    return false;
  }
  return (
    fsmState.value === FillCycleState.EMPTY ||
    fsmState.value === FillCycleState.EMPTYING ||
    fsmState.value === FillCycleState.EMPTYING_WITH_INGRESS ||
    fsmState.value === FillCycleState.FILLING ||
    fsmState.value === FillCycleState.FILLING_NO_INGRESS ||
    fsmState.value === FillCycleState.FULL ||
    fsmState.value === FillCycleState.PASS_THROUGH
  );
};
const getSvgUrl = (fsmState: AtomicReading<FillCycleState>) => {
  if (!fsmState || !fsmState.value) {
    return '/svg/Nothing.svg';
  }
  switch (fsmState.value) {
    case FillCycleState.CYCLING: {
      return '/svg/tier/Cycling.svg';
    }
    case FillCycleState.EMPTY: {
      return '/svg/tier/Empty.svg';
    }
    case FillCycleState.EMPTYING: {
      return '/svg/tier/Emptying.svg';
    }
    case FillCycleState.EMPTYING_WITH_INGRESS: {
      return '/svg/tier/EmptyingWithIngress.svg';
    }
    case FillCycleState.FILLING: {
      return '/svg/tier/Filling.svg';
    }
    case FillCycleState.FILLING_NO_INGRESS: {
      return '/svg/tier/FillingNoIngress.svg';
    }
    case FillCycleState.FULL: {
      return '/svg/tier/Full.svg';
    }
    case FillCycleState.PASS_THROUGH: {
      return '/svg/tier/PassThrough.svg';
    }
    case FillCycleState.IDLE: {
      return '/svg/Nothing.svg';
    }
    default: {
      return '/svg/Nothing.svg';
    }
  }
};

export default TierFillCycleFsmIcon;
