import { DEFAULT_SOAK_TIME_MINUTES } from 'model/src/defaults/DefaultValues';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React, { useState } from 'react';
import styled from 'styled-components';

import { cssVar } from '../..';
import ClassNames from '../../ClassNames';
import useWindowDimensions from '../../utopia/desktop/useWindowDimensions';
import Toggle from '../../utopia/toggle/Toggle';
import CancelButton from '../../utopia/window/components/CancelButton';
import ConfirmButton from '../../utopia/window/components/ConfirmButton';
import StaticWindow from '../../utopia/window/StaticWindow';
import WindowType from '../../utopia/window/WindowTypes';

const Content = styled.div`
  width: var(--confirmationWindowWidth);
  height: var(--confirmationWindowHeight);
  padding: var(--windowPadding);
  border-radius: var(--windowBorderRadius);

  color: var(--black);
  background-color: var(--windowBackground_Regular);
`;

const TextContainer = styled.div`
  padding-bottom: 32px;
`;
const TierPadding = styled.span`
  padding-right: 16px;
`;
const TierPaddingXLarge = styled.span`
  padding-right: 64px;
`;

const checkEventHandler = eventHandler => {
  if (typeof eventHandler === BuiltinType.FUNCTION) {
    eventHandler();
  }
};

export type TowerWaterCycleConfirmationBoxProps = {
  queryText?: string;
  title?: string;
  yesButtonEventHandler?: () => void;
  noButtonEventHandler?: () => void;
};

const ConfirmationBox = props => {
  const { screenHeight, screenWidth } = useWindowDimensions();

  const [fillTier1, setFillTier1] = useState(false);
  const [fillTier2, setFillTier2] = useState(false);
  const [fillTier3, setFillTier3] = useState(false);
  const [fillTier4, setFillTier4] = useState(false);
  const [fillTier5, setFillTier5] = useState(false);
  const [fillTier6, setFillTier6] = useState(false);
  const [fillTier7, setFillTier7] = useState(false);

  const [tier1SoakTimeMinutes, setTier1SoakTimeMinutes] = useState(
    DEFAULT_SOAK_TIME_MINUTES
  );
  const [tier2SoakTimeMinutes, setTier2SoakTimeMinutes] = useState(
    DEFAULT_SOAK_TIME_MINUTES
  );
  const [tier3SoakTimeMinutes, setTier3SoakTimeMinutes] = useState(
    DEFAULT_SOAK_TIME_MINUTES
  );
  const [tier4SoakTimeMinutes, setTier4SoakTimeMinutes] = useState(
    DEFAULT_SOAK_TIME_MINUTES
  );
  const [tier5SoakTimeMinutes, setTier5SoakTimeMinutes] = useState(
    DEFAULT_SOAK_TIME_MINUTES
  );
  const [tier6SoakTimeMinutes, setTier6SoakTimeMinutes] = useState(
    DEFAULT_SOAK_TIME_MINUTES
  );
  const [tier7SoakTimeMinutes, setTier7SoakTimeMinutes] = useState(
    DEFAULT_SOAK_TIME_MINUTES
  );

  const includeTiers = [
    fillTier1,
    fillTier2,
    fillTier3,
    fillTier4,
    fillTier5,
    fillTier6,
    fillTier7,
  ];
  const soakTimes = [
    tier1SoakTimeMinutes,
    tier2SoakTimeMinutes,
    tier3SoakTimeMinutes,
    tier4SoakTimeMinutes,
    tier5SoakTimeMinutes,
    tier6SoakTimeMinutes,
    tier7SoakTimeMinutes,
  ];

  const buildFillPattern = () => {
    const fillPattern: {
      tier: number;
      soakTimeMinutes: number;
    }[] = [];
    for (let i = 0; i < includeTiers.length; i += 1) {
      if (includeTiers[i]) {
        fillPattern.push({
          tier: i + 1,
          soakTimeMinutes: soakTimes[i],
        });
      }
    }
    return fillPattern;
  };

  return (
    <StaticWindow
      title={'Start Water Cycle'}
      url={props.url}
      initialWindowPosition={{
        left:
          (screenWidth -
            +cssVar('--confirmationWindowWidth').replace('px', '')) /
          2,
        top:
          (screenHeight -
            +cssVar('--confirmationWindowWidth').replace('px', '')) /
          2,
      }}
      selected={props.selected}
      windowType={WindowType.TOWER_WATER_CYCLE}>
      <Content>
        <TextContainer>
          <div className={ClassNames.title3} style={{ paddingBottom: '16px' }}>
            {props.title}
          </div>
        </TextContainer>
        <TextContainer>
          <TierPaddingXLarge>
            Fill Tier 1:
            <TierPadding />
            <Toggle
              defaultPosition={fillTier1}
              onChange={() => {
                setFillTier1(!fillTier1);
              }}
            />
          </TierPaddingXLarge>
          Soak Time:
          <TierPadding />
          <input
            id="tier1SoakTime"
            type="number"
            value={tier1SoakTimeMinutes}
            onChange={event => setTier1SoakTimeMinutes(+event.target.value)}
            min="0"
            max="300"
            step="1"
            disabled={!fillTier1}
          />
        </TextContainer>
        <TextContainer>
          <TierPaddingXLarge>
            Fill Tier 2:
            <TierPadding />
            <Toggle
              defaultPosition={fillTier2}
              onChange={() => {
                setFillTier2(!fillTier2);
              }}
            />
          </TierPaddingXLarge>
          Soak Time:
          <TierPadding />
          <input
            id="tier2SoakTime"
            type="number"
            value={tier2SoakTimeMinutes}
            onChange={event => setTier2SoakTimeMinutes(+event.target.value)}
            min="0"
            max="300"
            step="1"
            disabled={!fillTier2}
          />
        </TextContainer>
        <TextContainer>
          <TierPaddingXLarge>
            Fill Tier 3:
            <TierPadding />
            <Toggle
              defaultPosition={fillTier3}
              onChange={() => {
                setFillTier3(!fillTier3);
              }}
            />
          </TierPaddingXLarge>
          Soak Time:
          <TierPadding />
          <input
            id="tier3SoakTime"
            type="number"
            value={tier3SoakTimeMinutes}
            onChange={event => setTier3SoakTimeMinutes(+event.target.value)}
            min="0"
            max="300"
            step="1"
            disabled={!fillTier3}
          />
        </TextContainer>
        <TextContainer>
          <TierPaddingXLarge>
            Fill Tier 4:
            <TierPadding />
            <Toggle
              defaultPosition={fillTier4}
              onChange={() => {
                setFillTier4(!fillTier4);
              }}
            />
          </TierPaddingXLarge>
          Soak Time:
          <TierPadding />
          <input
            id="tier4SoakTime"
            type="number"
            value={tier4SoakTimeMinutes}
            onChange={event => setTier4SoakTimeMinutes(+event.target.value)}
            min="0"
            max="300"
            step="1"
            disabled={!fillTier4}
          />
        </TextContainer>
        <TextContainer>
          <TierPaddingXLarge>
            Fill Tier 5:
            <TierPadding />
            <Toggle
              defaultPosition={fillTier5}
              onChange={() => {
                setFillTier5(!fillTier5);
              }}
            />
          </TierPaddingXLarge>
          Soak Time:
          <TierPadding />
          <input
            id="tier5SoakTime"
            type="number"
            value={tier5SoakTimeMinutes}
            onChange={event => setTier5SoakTimeMinutes(+event.target.value)}
            min="0"
            max="300"
            step="1"
            disabled={!fillTier5}
          />
        </TextContainer>
        <TextContainer>
          <TierPaddingXLarge>
            Fill Tier 6:
            <TierPadding />
            <Toggle
              defaultPosition={fillTier6}
              onChange={() => {
                setFillTier6(!fillTier6);
              }}
            />
          </TierPaddingXLarge>
          Soak Time:
          <TierPadding />
          <input
            id="tier6SoakTime"
            type="number"
            value={tier6SoakTimeMinutes}
            onChange={event => setTier6SoakTimeMinutes(+event.target.value)}
            min="0"
            max="300"
            step="1"
            disabled={!fillTier6}
          />
        </TextContainer>
        <TextContainer>
          <TierPaddingXLarge>
            Fill Tier 7:
            <TierPadding />
            <Toggle
              defaultPosition={fillTier7}
              onChange={() => {
                setFillTier7(!fillTier7);
              }}
            />
          </TierPaddingXLarge>
          Soak Time:
          <TierPadding />
          <input
            id="tier7SoakTime"
            type="number"
            value={tier7SoakTimeMinutes}
            onChange={event => setTier7SoakTimeMinutes(+event.target.value)}
            min="0"
            max="300"
            step="1"
            disabled={!fillTier7}
          />
        </TextContainer>
        <div style={{ float: 'right' }}>
          <CancelButton
            onClick={() => {
              checkEventHandler(props.noButtonEventHandler);
              props.closeHandler();
            }}>
            {props.noButtonText}
          </CancelButton>
          <ConfirmButton
            onClick={() => {
              const fillPattern = buildFillPattern();
              checkEventHandler(() => props.yesButtonEventHandler(fillPattern));
              props.closeHandler();
            }}>
            {props.yesButtonText}
          </ConfirmButton>
        </div>
      </Content>
    </StaticWindow>
  );
};

export default ConfirmationBox;

ConfirmationBox.defaultProps = {
  queryText: 'Water???',
  yesButtonText: 'Confirm',
  noButtonText: 'Cancel',
  title: 'Start Water Cycle',
};
