/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import TowerBaseBottomPodTop_Model from './TowerBaseBottomPodTop.glb';

export default function TowerBaseBottomPodTop(props) {
  const group = useRef();
  const { nodes } = useGLTF(TowerBaseBottomPodTop_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-77, 0, 0.63]}>
        <group position={[84, -75, 35.51]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1.material
                : material
            }
          />
        </group>
        <group position={[74, -75, 35.51]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_1.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_1.material
                : material
            }
          />
        </group>
        <group position={[70, -75, 35.51]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_2.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_2.material
                : material
            }
          />
        </group>
        <group position={[80, -75, 35.51]} rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.Body1_3.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_3.material
                : material
            }
          />
        </group>
      </group>
      <group position={[-77, 0, 8.64]}>
        <mesh
          geometry={nodes.Spring_Assembly_Top_Plate_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Spring_Assembly_Top_Plate_1.material
              : material
          }
        />
      </group>
      <group position={[-77, 0, 8.64]}>
        <group position={[84, -13.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_4.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_4.material
                : material
            }
          />
        </group>
        <group position={[84, -11.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_5.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_5.material
                : material
            }
          />
        </group>
        <group position={[70, -57.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_6.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_6.material
                : material
            }
          />
        </group>
        <group position={[84, -57.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_7.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_7.material
                : material
            }
          />
        </group>
        <group position={[70, 11.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_8.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_8.material
                : material
            }
          />
        </group>
        <group position={[84, -55.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_9.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_9.material
                : material
            }
          />
        </group>
        <group position={[84, -51.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_10.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_10.material
                : material
            }
          />
        </group>
        <group position={[84, -53.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_11.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_11.material
                : material
            }
          />
        </group>
        <group position={[84, 17.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_12.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_12.material
                : material
            }
          />
        </group>
        <group position={[84, 15.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_13.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_13.material
                : material
            }
          />
        </group>
        <group position={[84, 13.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_14.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_14.material
                : material
            }
          />
        </group>
        <group position={[84, 11.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_15.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_15.material
                : material
            }
          />
        </group>
        <group position={[70, 13.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_16.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_16.material
                : material
            }
          />
        </group>
        <group position={[70, 17.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_17.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_17.material
                : material
            }
          />
        </group>
        <group position={[70, 15.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_18.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_18.material
                : material
            }
          />
        </group>
        <group position={[70, -55.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_19.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_19.material
                : material
            }
          />
        </group>
        <group position={[70, -51.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_20.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_20.material
                : material
            }
          />
        </group>
        <group position={[70, -53.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_21.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_21.material
                : material
            }
          />
        </group>
        <group position={[84, -15.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_22.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_22.material
                : material
            }
          />
        </group>
        <group position={[84, -17.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_23.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_23.material
                : material
            }
          />
        </group>
        <group position={[70, -15.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_24.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_24.material
                : material
            }
          />
        </group>
        <group position={[70, -17.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_25.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_25.material
                : material
            }
          />
        </group>
        <group position={[70, -11.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_26.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_26.material
                : material
            }
          />
        </group>
        <group position={[70, -13.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_27.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_27.material
                : material
            }
          />
        </group>
        <group position={[80, -68, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_28.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_28.material
                : material
            }
          />
        </group>
        <group position={[74, -70, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_29.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_29.material
                : material
            }
          />
        </group>
        <group position={[80, -70, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_30.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_30.material
                : material
            }
          />
        </group>
        <group position={[74, -68, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_31.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_31.material
                : material
            }
          />
        </group>
        <group position={[80, 68, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_32.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_32.material
                : material
            }
          />
        </group>
        <group position={[74, -1, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_33.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_33.material
                : material
            }
          />
        </group>
        <group position={[80, -1, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_34.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_34.material
                : material
            }
          />
        </group>
        <group position={[84, 57.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_35.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_35.material
                : material
            }
          />
        </group>
        <group position={[84, 55.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_36.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_36.material
                : material
            }
          />
        </group>
        <group position={[84, 53.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_37.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_37.material
                : material
            }
          />
        </group>
        <group position={[84, 51.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_38.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_38.material
                : material
            }
          />
        </group>
        <group position={[74, 1, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_39.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_39.material
                : material
            }
          />
        </group>
        <group position={[80, 1, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_40.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_40.material
                : material
            }
          />
        </group>
        <group position={[70, 53.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_41.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_41.material
                : material
            }
          />
        </group>
        <group position={[70, 51.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_42.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_42.material
                : material
            }
          />
        </group>
        <group position={[70, 57.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_43.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_43.material
                : material
            }
          />
        </group>
        <group position={[74, 70, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_44.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_44.material
                : material
            }
          />
        </group>
        <group position={[74, 68, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_45.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_45.material
                : material
            }
          />
        </group>
        <group position={[70, 55.5, 25.51]}>
          <mesh
            geometry={nodes.Body1_46.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_46.material
                : material
            }
          />
        </group>
        <group position={[80, 70, 24.88]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_47.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_47.material
                : material
            }
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(TowerBaseBottomPodTop_Model);
