/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGLTF } from '@react-three/drei';

import { RenderingType } from '../../RenderingType';
import materialFactory from '../../tug/MaterialFactory';
import DoorFrame_Model from './DoorFrame.glb';

export default function DoorFrame(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(DoorFrame_Model) as GLTF;

  const material: THREE.Material = materialFactory.generateMaterialObject(
    props.parentUnderInspection,
    props.parentHovered,
    props.online
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0, -2]}>
        <mesh
          geometry={nodes['2080_500_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(2)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(2)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2040_600_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040_600_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2040_600_(1)_(1)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2040_600_(1)_(1)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(3)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(3)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(3)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(3)_(1)'].material
              : material
          }
        />
        <mesh
          geometry={nodes['2080_500_(2)_(1)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['2080_500_(2)_(1)'].material
              : material
          }
        />
      </group>
      <group
        position={[3.79, -3.05, 58.2]}
        rotation={[-Math.PI, 0, Math.PI / 2]}>
        <mesh
          geometry={nodes.Body1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? materials['Steel - Satin']
              : material
          }
        />
      </group>
      <group position={[3.54, -5, 55.4]} rotation={[-Math.PI, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Body1_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_1.material
              : material
          }
        />
      </group>
      <group position={[3.29, -5.32, 55.84]} rotation={[0, Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Body1_2.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_2.material
              : material
          }
        />
      </group>
      <group position={[5.26, -7.8, 50.2]} rotation={[0, 0, Math.PI / 2]}>
        <mesh
          geometry={nodes.EdgeCap.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? materials['Plastic - Matte (White)']
              : material
          }
        />
      </group>
      <group position={[3.25, -7.29, 54.59]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Body1_Chrome.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_Chrome.material
              : material
          }
        />
        <mesh
          geometry={nodes['Body1_Plastic_-_Glossy_(Black)'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Body1_Plastic_-_Glossy_(Black)'].material
              : material
          }
        />
        <mesh
          geometry={nodes.Body4.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body4.material
              : material
          }
        />
      </group>
      <group position={[4.24, -5, 55.4]} rotation={[0, -Math.PI / 2, 0]}>
        <group position={[0, 0, 0.7]} rotation={[-Math.PI, 0, 0]}>
          <mesh
            geometry={nodes.Body1_8.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_8.material
                : material
            }
          />
        </group>
        <group position={[0, 0, 1.05]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes.Body1_9.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? materials['Opaque(160,160,160)']
                : material
            }
          />
        </group>
        <group position={[0, 0, 1.3]} rotation={[0, -1.57, 0]} />
        <group position={[0, 0, 1.3]} rotation={[1.57, 0, 0]}>
          <mesh
            geometry={nodes.Body1_11.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_11.material
                : material
            }
          />
        </group>
        <group position={[0, 0, 0.7]} rotation={[1.57, 0, 0]}>
          <mesh
            geometry={nodes.Body1_12.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_12.material
                : material
            }
          />
        </group>
        <group position={[0, 0, 1.4]}>
          <mesh
            geometry={nodes.Body1_13.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_13.material
                : material
            }
          />
        </group>
        <group position={[0, 0, 1.7]} rotation={[1.57, 0, 0]}>
          <mesh
            geometry={nodes.Body1_14.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_14.material
                : material
            }
          />
        </group>
        <group position={[0, 0, 2.05]} rotation={[0, -Math.PI / 2, 0]} />
        <group position={[0, 0, 2.3]} rotation={[1.57, 0, 0]}>
          <mesh
            geometry={nodes.Body1_16.geometry}
            material={
              props.renderingType === RenderingType.CadBased
                ? nodes.Body1_16.material
                : material
            }
          />
        </group>
        <mesh
          geometry={nodes.Main_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Main_1.material
              : material
          }
        />
      </group>
      <group position={[5.26, -7.8, 57.85]} rotation={[0, 0, Math.PI / 2]} />
      <group position={[3.25, -7.29, 56.75]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Body1_Chrome_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body1_Chrome_1.material
              : material
          }
        />
        <mesh
          geometry={nodes['Body1_Plastic_-_Glossy_(Black)_1'].geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes['Body1_Plastic_-_Glossy_(Black)_1'].material
              : material
          }
        />
        <mesh
          geometry={nodes.Body4_1.geometry}
          material={
            props.renderingType === RenderingType.CadBased
              ? nodes.Body4_1.material
              : material
          }
        />
      </group>
      <mesh
        geometry={nodes.Motor_Side_Plate.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes.Motor_Side_Plate.material
            : material
        }
      />
      <mesh
        geometry={nodes.Non_Motor_Side_Plate.geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes.Non_Motor_Side_Plate.material
            : material
        }
      />
      <mesh
        geometry={nodes['Motor_Side_Plate_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['Motor_Side_Plate_(1)'].material
            : material
        }
      />
      <mesh
        geometry={nodes['Non_Motor_Side_Plate_(1)'].geometry}
        material={
          props.renderingType === RenderingType.CadBased
            ? nodes['Non_Motor_Side_Plate_(1)'].material
            : material
        }
      />
    </group>
  );
}

useGLTF.preload(DoorFrame_Model);
